
import { Vue, Component, Watch } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import GkDatePicker from '@/components/DatePicker.vue'
import _ from 'lodash'
import moment from 'moment'
import { map } from 'rxjs/operators'

@Component({ components: { GkDatePicker } })
export default class ApplicationChannels extends Vue {
  private root!: VueGevekom

  private branchesList: any[] = []
  private statusList: any[] = []
  private list: any[] = []

  private dateRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
  private disableLoadButton = false

  private tableItems: any[] = []
  private tableSumRow: any = { branch: 'Summen:', applications: 0 }
  private tablePercentRow: any = { branch: 'in %:', applications: '' }
  private tableHeaders = [
    { text: 'Standort', value: 'branch', sortable: false, align: 'start' },
    { text: 'Bewerbungen', value: 'applications', sortable: false, align: 'center' }
  ]

  @Watch('dateRange')
  watchDateRange(newValue: any, oldValue: any) {
    console.log('[ApplicationChannels] Watched dateRange NEW:', newValue)
    console.log('[ApplicationChannels] Watched dateRange OLD:', oldValue)
    if (newValue && newValue.length === 2) {
      console.log('[ApplicationChannels] Jetzt sollten neue Daten für die neue Range abgeholt und angezeigt werden!')
      const range = [...newValue]
      range.sort()
      // this.dateRange.sort()
      this.disableLoadButton = false
    } else {
      this.tableHeaders = [
        { text: 'Standort', value: 'branch', sortable: false, align: 'start' },
        { text: 'Bewerbungen', value: 'applications', sortable: false, align: 'center' }
      ]
      this.tableSumRow = { branch: 'Summen:', applications: 0 }
      this.tablePercentRow = { branch: 'in %:', applications: '' }
      this.tableItems = []
      this.disableLoadButton = true
    }
  }

  get dateRangeText(): string {
    return `Zeitraum gewählt vom ${moment(new Date(this.dateRange[0])).format('DD.MM.YYYY')} ${
      this.dateRange[1] ? ` bis ${moment(new Date(this.dateRange[1])).format('DD.MM.YYYY')}` : ''
    }`
  }

  created() {
    console.log('[ApplicationChannels] IS CREATED')
    this.root = this.$root as VueGevekom
    // this.$store.commit('showProgressBar')

    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.applicationService.initService(tenantId, token)
    }
  }

  private loadApplicationsForRange(rangeStart: string, rangeEnd: string) {
    this.$store.commit('showProgressBar')
    this.root.applicationService
      .getApplicationChannelsStats$(rangeStart, rangeEnd)
      .pipe(
        map((response: any) => {
          return response.data.statistics
        })
      )
      .subscribe((statsResponse: any) => {
        console.log('[ApplicationChannels] Received new data collection for stats:', statsResponse)
        this.reCalcTableItems(statsResponse)
      })
  }

  loadRangeSelection() {
    console.log('[ApplicationChannels] Load range selection')
    this.loadApplicationsForRange(this.dateRange[0], this.dateRange[1])
  }

  dateRangeChanged(evt: any) {
    console.log('[ApplicationChannels] Date range changed:', evt)
    this.dateRange.sort()
  }

  private reCalcTableItems(statsResponse: any) {
    console.log('[ApplicationChannels] received application channel stats:', statsResponse)
    const applicationChannelsConfig = this.$store.state.company.configs.applicationsChannels
    this.tableHeaders = [
      { text: 'Standort', value: 'branch', sortable: false, align: 'start' },
      { text: 'Bewerbungen', value: 'applications', sortable: false, align: 'center' }
    ]
    const newSumRow: any = {
      branch: 'Summen:',
      applications: 0
    }
    const newPercentRow: any = {
      branch: 'in %:',
      applications: '-'
    }

    for (const channel of statsResponse.channels) {
      const channelCfg = _.find(applicationChannelsConfig, { value: channel })
      this.tableHeaders.push({
        text: (channelCfg && channelCfg.displayText) || (channel === 'undefined' ? 'keine Angabe' : channel),
        value: channel,
        sortable: false,
        align: 'center'
      })
      newSumRow[channel] = 0
      newPercentRow[channel] = 0

      this.tableItems = []
      for (const key of Object.keys(statsResponse.counts)) {
        const newRow: any = { branch: key.toLocaleUpperCase() }
        for (const channel of statsResponse.channels) {
          newRow[channel] = statsResponse.counts[key][channel] || 0
        }
        this.tableItems.push(newRow)
      }

      this.tableSumRow = newSumRow
      this.tablePercentRow = newPercentRow
    }

    for (const row of this.tableItems) {
      row.applications = 0
      for (const channel of Object.keys(row)) {
        if (channel !== 'branch' && channel !== 'applications') {
          newSumRow[channel] += row[channel]
          row.applications += row[channel]
        }
      }
      newSumRow.applications += row.applications
    }
    console.log('[ApplicationChannels] newSumRow after recalculation:', newSumRow)
    for (const key in newSumRow) {
      if (key !== 'branch' && key !== 'applications') {
        newPercentRow[key] = `${((newSumRow[key] / statsResponse.applicationsCount) * 100).toFixed(2)}%`
      }
    }
    console.log('[ApplicationChannels] newPercentRow after recalculation:', newPercentRow)
    this.$store.commit('hideProgressBar')
  }
}
