
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
// import moment from 'moment'

@Component
export default class GkTimePicker extends Vue {
  // @Prop({ default: false }) readonly showDialog!: boolean
  @Prop({ default: 'Zeit auswählen' }) readonly timePickerLabel!: string
  @Prop() readonly value!: any

  //   private refPicker: any
  private showPickerMenu = false

  private pickerTime: any = null
  private pickerTimeInput: any = null

  @Emit('input')
  emitVModel(value: any) {
    console.log('[GkTimePicker] input emit for v-model support:', value)
    return value
  }

  @Watch('pickerTime')
  watchPickerDate(newValue: any) {
    console.log('[GkTimePicker] Watched pickerTime:', newValue)
    this.emitVModel(newValue)
    this.pickerTimeInput = newValue
  }

  @Watch('pickerTimeInput')
  watchPickerTimeInput(newValue: any) {
    console.log('[GkTimePicker] Watched pickerTimeInput:', newValue)
    this.pickerTime = newValue
    this.emitVModel(this.pickerTime)
  }

  constructor() {
    super()
    // console.log('[GkTimePicker] v-model:', this.value)
    if (this.value) {
      this.pickerTime = this.value
      this.pickerTimeInput = this.value
    } else {
      this.pickerTime = ''
      this.pickerTimeInput = ''
    }
  }

  hourSelected(evt: any) {
    console.log('[GkTimePicker] hour slected:', evt)
  }

  minuteSelected(evt: any) {
    console.log('[GkTimePicker] minute slected:', evt)
    console.log('[GkTimePicker] picker time:', this.pickerTime)
    // this.$refs.refPicker.save(pickerTime)
  }
}
