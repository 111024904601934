
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
// import moment from 'moment'
import _ from 'lodash'
import ApplicationsSingletonService, { IndexForMassMailingOptions } from '@/appServices/applications.singleton.service'
import GkDatePicker from '@/components/DatePicker.vue'

@Component({ components: { GkDatePicker } })
export default class FilterRow extends Vue {
  @Prop({ default: null }) readonly applicationService!: ApplicationsSingletonService
  @Prop({ default: [] }) readonly exportFields!: string[]

  private loadingApplications = false

  private originalData!: any[]

  private jobs: { displayText: string; value: string }[] = []
  private job: string | null = ''

  private channels: { displayText: string; value: string }[] = []
  private channel: string | null = ''

  private branches = [{ displayText: 'Alle', value: 'all' }]
  private branch: string[] | null = ['Alle']

  private statusTypes: { displayText: string; value: string }[] = []
  private statusType: string | null = null

  private lastName = ''
  private firstName = ''
  private applicationReceivedAtSince = ''
  private applicationReceivedAtUntil = ''
  private hiredAt = ''

  @Watch('branch')
  onBranchChanged(newValue: string[], oldValue: string[]) {
    console.log('[FilterRow] branch selection changed:', newValue)
    console.log('[FilterRow] branch selection changed oldValue:', oldValue)
    if (oldValue.includes('Alle') && newValue?.length > 1) {
      _.remove(this.branch || [], (item: string) => {
        return item === 'Alle'
      })
    }
    if (!oldValue.includes('Alle') && newValue.includes('Alle')) {
      this.branch = ['Alle']
    }
    if (this.branch?.length === 0) {
      this.branch = ['Alle']
    }
    console.log('[FilterRow] branch selection changed v-model:', this.branch)
  }

  @Watch('statusType')
  onStatusTypeChanged(newValue: string[], oldValue: string[]) {
    console.log('[FilterRow] statusType selection changed:', newValue)
    console.log('[FilterRow] statusType selection changed oldValue:', oldValue)
    console.log('[FilterRow] branch selection changed v-model:', this.statusType)
  }

  @Emit('filteredData')
  filteredData(applications: any[]) {
    console.log('[MassMailing -> FilterRow] emit filtered data:', applications)
    return applications
  }

  created() {
    this.statusTypes = [...this.statusTypes, ...this.$store.state.company.configs.status]
    this.branches = [...this.branches, ...this.$store.state.company.configs.branches]
    this.jobs = [...this.jobs, ...this.$store.state.company.configs.jobs]
    this.channels = [...this.channels, ...this.$store.state.company.configs.applicationsChannels]
  }

  loadApplicationsWithFilterParams() {
    this.$store.commit('showProgressBar')
    this.loadingApplications = true

    let branches: string[] = []
    for (const branch of this.branch || []) {
      if (branch === 'Alle') {
        branches = []
        for (const b of this.branches) {
          branches.push(b.value)
        }
        break
      }
      branches.push(_.find(this.branches, { displayText: branch })?.value || branch)
    }

    const status: string = (_.find(this.statusTypes, { displayText: this.statusType }) as { displayText: string; value: string }).value

    const options: IndexForMassMailingOptions = {
      status,
      branches,
      applicationReceivedAtSince: this.applicationReceivedAtSince,
      applicationReceivedAtUntil: this.applicationReceivedAtUntil
    }
    if (this.hiredAt?.length > 0) {
      options.hiredAt = this.hiredAt
    }
    if (this.lastName?.length > 0) {
      options.lastName = this.lastName
    }
    if (this.firstName?.length > 0) {
      options.firstName = this.firstName
    }
    if (this.jobs && this.job && this.job.length > 0) {
      options.applicationAs = _.find(this.jobs, { displayText: this.job })?.value || ''
    }
    if (this.channels && this.channel && this.channel.length > 0) {
      options.applicationsChannel = _.find(this.channels, { displayText: this.channel })?.value || ''
    }
    if (this.hiredAt && this.hiredAt.length > 0) {
      options.hiredAt = this.hiredAt
    }

    this.applicationService.getIndexForMassMailing$(options).subscribe(
      (response: any) => {
        console.log('[MassMailing -> FilterRow] Get applications index from Server:', response)
        // this.$store.commit('setIncomingApplications', response.data.applications)
        // this.createFilteredData([...response.data.applications])
        this.filteredData([...response.data.applications])
        this.$store.commit('hideProgressBar')
        this.loadingApplications = false
      },
      (err: any) => {
        console.error('[MassMailing -> FilterRow] ERROR:', err)
        // this.$store.commit('setIncomingApplications', [])
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
        this.$store.commit('hideProgressBar')
        this.loadingApplications = false
      }
    )
    // setTimeout(() => {
    //   this.createFilteredData([])
    //   this.$store.commit('hideProgressBar')
    // }, 2000)
  }

  resetFilterSettings() {
    console.log('[FilterRow] reset all filter settings.')
    this.job = ''
    this.channel = ''
    this.branch = ['Alle']
    this.statusType = null
    this.lastName = ''
    this.firstName = ''
    this.applicationReceivedAtSince = ''
    this.applicationReceivedAtUntil = ''
    this.hiredAt = ''
    this.$forceUpdate()
  }

  hideFilterButton() {
    // console.log(`[MassMailing -> FilterRow] validating statusType:`, this.statusType)
    if (!this.statusType || this.statusType.length === 0) {
      return true
    }
    return false
  }

  filterWithParams() {
    console.log('[MassMailing -> FilterRow] filter data.')
    // console.log('[MassMailing -> FilterRow] filter type:', this.filterType)
    // console.log('[MassMailing -> FilterRow] filter year:', this.filterYear)
    // console.log('[MassMailing -> FilterRow] filter month:', this.filterMonth)
    // console.log('[MassMailing -> FilterRow] filter quartal:', this.filterQuartal)
    let originalData = [...this.originalData]

    if (!this.branch?.includes('Alle')) {
      originalData = _.filter(originalData, (item: any) => {
        if (item) {
          return this.branch?.includes(item.branch)
        }
        return false
      })
    }

    // if (!this.statusType?.includes('Alle')) {
    //   originalData = _.filter(originalData, (item: any) => {
    //     if (item) {
    //       return this.statusType?.includes(item.status)
    //     }
    //     return false
    //   })
    // }

    this.filteredData(originalData)
  }

  private createFilteredData(fromApplications: any) {
    this.originalData = _.map(fromApplications, (appl: any) => {
      const d: any = {}

      for (const key of this.exportFields) {
        if (
          [
            'rejectedReason',
            'languageSkill',
            'writingSkill',
            'applicationAs',
            'feedbackTest',
            'project',
            'branch',
            'sex',
            'status',
            'recruiter',
            'skills'
          ].includes(key)
        ) {
          let displayText: any
          switch (key) {
            case 'sex':
              displayText = _.find(this.$store.state.company.configs.sex, { value: appl[key] })
              break
            case 'status':
              displayText = _.find(this.$store.state.company.configs.status, { value: appl[key] })
              break
            case 'branch':
              displayText = _.find(this.$store.state.company.configs.branches, { value: appl[key] })
              break
            case 'applicationChannel':
              displayText = _.find(this.$store.state.company.configs.applicationChannels, { value: appl[key] })
              break
            case 'project':
              displayText = _.find(this.$store.state.company.configs.projects, { value: appl[key] })
              break
            case 'feedbackTest':
              displayText = _.find(this.$store.state.company.configs.feedbackTests, { value: appl[key] })
              break
            case 'languageSkill':
              displayText = _.find(this.$store.state.company.configs.languageSkills, { value: appl[key] })
              break
            case 'writingSkill':
              displayText = _.find(this.$store.state.company.configs.writingSkills, { value: appl[key] })
              break
            case 'rejectedReason':
              displayText = _.find(this.$store.state.company.configs.rejectReasons, { value: appl[key] })
              break
            case 'recruiter':
              displayText = _.find(this.$store.state.company.configs.recruiter, { value: appl[key] })
              break
            case 'skills':
              displayText = { displayText: '' }
              if (appl.skills?.length > 0) {
                appl.skills.forEach((skill: any) => {
                  if (skill.active && skill.checked) {
                    displayText = { displayText: `${displayText.displayText}${skill.displayName}, ` }
                  }
                })
                if (displayText.displayText.length > 0) {
                  displayText = { displayText: displayText.displayText.slice(0, -2) }
                }
              }
              break
            default:
              break
          }
          d[key] = displayText?.displayText || null
        } else {
          d[key] = appl[key] || null
        }
      }
      return d
    })
    console.log('[MassMailing -> FilterRow] filled array of applications:', this.originalData)
    this.filterWithParams()
  }
}
