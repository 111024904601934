
import { Vue, Component } from 'vue-property-decorator'
import StatisticsMain from '@/components/statistics/Main.vue'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { ErrorSnackData } from '@/components/dialogs/ErrorSnack.vue'
import { BehaviorSubject } from 'rxjs'

@Component({
  components: {
    StatisticsMain
  }
})
export default class Statistics extends Vue {
  private root!: VueGevekom
  private title = 'Statistiken'

  applicationsList$ = new BehaviorSubject<any[]>([])

  private showErrorSnack = false
  private errorSnackData: ErrorSnackData = {
    status: 500,
    errorMessage: '',
    timeout: 6000
  }

  private errorSnack = false
  private errorSnackText = ''

  created() {
    console.log('[Statistics] Created the Applications class')
    this.root = this.$root as VueGevekom
    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken
      // const token = ''

      this.root.applicationService.initService(tenantId, token)
    }
    if (!this.root.emailTemplatesService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.emailTemplatesService.initService(tenantId, token)
    }
    this.loadInitData()
  }

  loadInitData() {
    this.$store.commit('showProgressBar')
    this.root.applicationService.getDataConfigs$().subscribe(
      (response: any) => {
        console.log('[Statistics] Get data configs from Server:', response)
        this.$store.commit('setConfigs', response?.data?.configs)
        // this.loadApplications()
        this.$store.commit('hideProgressBar')
      },
      (err: any) => {
        console.error('[Statistics] ERROR:', err)
        this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
        this.applicationsList$.next([])
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
  }

  private loadApplications() {
    this.root.applicationService.getIndex$().subscribe(
      (response: any) => {
        console.log('[Statistics] Get applications index from Server:', response)
        this.$store.commit('setIncomingApplications', response.data.applications)
        this.$store.commit('hideProgressBar')
        this.applicationsList$.next(response.data.applications)
      },
      (err: any) => {
        console.error('[Statistics] ERROR:', err)
        this.$store.commit('setIncomingApplications', [])
        this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
        this.applicationsList$.next([])
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
  }

  test() {
    console.log('[Statistics] Test method called')
  }
}
