
import { VueGevekom } from '@/components/applications/Form.interfaces'
import FormApplicationsContent from '@/components/form-applications/FormApplicationsContent.vue'
import FormApplicationsHeader from '@/components/form-applications/Header.vue'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: { FormApplicationsHeader, FormApplicationsContent }
})
export default class FormApplications extends Vue {
  private root!: VueGevekom
  showFileUpload = false
  Images: any = null

  created() {
    console.log('[FormApplicationsComponent] Created the FormApplicationsComponent class')
    this.root = this.$root as VueGevekom
  }

  startImportCsvProcess() {
    console.log('[FormApplications] start import csv process')
    this.showFileUpload = !this.showFileUpload
  }

  uploadFile() {
    if (this.$refs && this.$refs.file) {
      this.Images = (this.$refs.file as any).files[0]
    }
  }

  submitFile() {
    let fileContent
    this.$store.commit('showProgressBar')

    console.log(`[FormApplications] submit file:`, this.Images)

    const reader = new FileReader()
    reader.onload = (e: any) => {
      fileContent = e.target.result
      console.log('File %s read and ready to handle.', this.Images.name, fileContent)
      this.root.applicationService.importCsvFormApplications$(fileContent).subscribe(
        (res) => {
          console.log('[FormApplications] import csv form applications success', res)
          this.$nextTick(() => {
            if (this.$refs && this.$refs.file) {
              ;(this.$refs.file as any).type = 'text'
              ;(this.$refs.file as any).type = 'file'
            }
            this.Images = null
          })

          this.$store.commit('hideProgressBar')
        },
        (err) => {
          console.log('[FormApplications] import csv form applications error', err)
          this.$nextTick(() => {
            if (this.$refs && this.$refs.file) {
              ;(this.$refs.file as any).type = 'text'
              ;(this.$refs.file as any).type = 'file'
            }
            this.Images = null
          })
          this.$store.commit('hideProgressBar')
        }
      )
    }
    reader.readAsText(this.Images)
  }
}
