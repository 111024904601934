
import { Component, Vue, Emit } from 'vue-property-decorator'
import { VueGevekom } from './Form.interfaces'
import _ from 'lodash'
import moment from 'moment'

@Component
export default class ApplicationDuplicatesCheck extends Vue {
  private root!: VueGevekom
  private confirmDeleteDialog = false
  private confirmIsNoDuplicateDialog = false
  private deleteApplicationId: string;
  private noDuplicateApplicationId: string;

  private duplicatesOriginalData: any = {}

  reloadApplicationDuplicates() {
    console.log('reloadApplicationDuplicates')
    this.$store.commit('showProgressBar')
    this._hideButtons = true
    this.duplicatesOriginalData = {}
    this.$nextTick(() => {
      this.root.applicationService.getCheckDoubleEmailApplications$().subscribe((data: any) => {
        console.log('reloadApplicationDuplicates data', data)
        this.duplicatesOriginalData = data
        this.$store.commit('hideProgressBar')
        this._hideButtons = false
      })
    })
  }

  @Emit('exitDuplicatesCheck')
  exitDuplicatesCheck() {
    console.log('exitDuplicatesCheck')
  }

  private _hideButtons = false
  public hideButtons(): boolean {
    return this._hideButtons || false
  }

  public limitRows = 25
  get limitApplicationsRows(): number {
    return this.limitRows
  }

  get hasDuplicatesList(): boolean {
    return Object.keys(this.duplicatesOriginalData).length > 0
  }

  get duplicatesOriginalDataKeys(): string[] {
    const result: string[] = []
    let limit = 0
    for (const key in this.duplicatesOriginalData) {
      if (this.duplicatesOriginalData[key].count > 1) {
        result.push(key)
        limit++
      }
      if (limit >= this.limitApplicationsRows) {
        break
      }
    }

    return result
  }

  get duplicatesOriginalDataKeysTotalLength(): number {
    return Object.keys(this.duplicatesOriginalData).length || 0
  }

  created() {
    this.root = this.$root as VueGevekom
  }

  getPropertyFromDuplicatesData(key: string, property: string) {
    return this.duplicatesOriginalData[key][property]
  }

  getApplicationsFromDuplicatesDataForKey(key: string) {
    const applications = this.duplicatesOriginalData[key].doubledApplications
    const result = []
    for (const key of Object.keys(applications)) {
      result.push(applications[key])
    }
    return result
  }

  deleteApplication(application: any) {
    console.log('[ApplicationDuplicatesCheck] Delete this application:', application)
    this.deleteApplicationId = application.id
    this.confirmDeleteDialog = true
  }

  deleteThisApplicationConfirmed() {
    console.log('[ApplicationDuplicatesCheck] Delete is confirmed for this applicationId:', this.deleteApplicationId)
    this.root.applicationService.delete$(this.deleteApplicationId).subscribe(result => {
      console.log('[ApplicationDuplicatesCheck] The application was deleted. Reload...', result)
      this.confirmDeleteDialog = false
      this.$nextTick(() => {
        this.reloadApplicationDuplicates()
      })
    })
  }

  setThisApplicationAsNoDuplicateConfirmed() {
    console.log('[ApplicationDuplicatesCheck] no duplicate is confirmed for this applicationId:', this.deleteApplicationId)
    this.root.applicationService.setIsNotADuplicateApplication$(this.noDuplicateApplicationId).subscribe(result => {
      console.log('[ApplicationDuplicatesCheck] The application was set as NO duplicate. Reload...', result)
      this.confirmIsNoDuplicateDialog = false
      this.noDuplicateApplicationId = ''
      this.$nextTick(() => {
        this.reloadApplicationDuplicates()
      })
    })
  }

  isNotADuplicateApplication(application: any) {
    console.log('isNotADuplicateApplication', application)
    this.noDuplicateApplicationId = application.id
    this.confirmIsNoDuplicateDialog = true
  }

  getStatusDisplayText(statusValue: string) {
    // console.log('[ApplicationsTable] Find displayText for status:', statusValue)
    const status = _.find(this.$store.state.company.configs.status, { value: statusValue })
    return status ? status.displayText : ''
  }

  getBranchDisplayText(branchValue: string) {
    // console.log('[ApplicationsTable] Find displayText for branch:', branchValue)
    const status = _.find(this.$store.state.company.configs.branches, { value: branchValue })
    return status ? status.displayText : ''
  }

  getDateText(date: string) {
    // console.log('[ApplicationsTable] Find displayText for branch:', branchValue)
    const datum = moment(date).format('DD.MM.YYYY')
    return datum
  }
}
