
import { Vue, Component, Prop } from 'vue-property-decorator'
import DailyFeedback from '@/components/statistics/DailyFeedback.vue'
import WeeklyFeedback from '@/components/statistics/WeeklyFeedback.vue'
import MonthlyFeedback from '@/components/statistics/MonthlyFeedback.vue'
import StatusFeedback from '@/components/statistics/StatusFeedback.vue'
import ApplicationChannels from '@/components/statistics/ApplicationChannels.vue'
import RecruiterStatistics from '@/components/statistics/RecruiterStatistics.vue'
import { BehaviorSubject, Observable } from 'rxjs'

@Component({ components: { DailyFeedback, WeeklyFeedback, MonthlyFeedback, StatusFeedback, ApplicationChannels, RecruiterStatistics } })
export default class StatisticsMain extends Vue {
  @Prop({ default: null }) readonly applicationsList!: Observable<any[]>

  private branches = new BehaviorSubject<any[]>([])

  created() {
    this.branches.next(this.$store.state.company.configs.branches)
  }
}
