
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class EmailPlaceholderSettings extends Vue {
  private root!: VueGevekom
  private myUserId!: string
  private mySettings: any = null

  @Prop({ default: true }) readonly showLoading!: boolean

  @Emit('handleShowLoading')
  handleShowLoading(newValue: boolean) {
    return newValue
  }

  private formIsValid = false
  private saveEmailPlaceholdersLoading = false
  private emailSenderName = ''
  private emailSenderPosition = ''
  private emailSenderReturnEmail = ''

  created() {
    console.log(`[EmailPlaceholderSettings] CREATED`)
    this.root = this.$root as VueGevekom

    this.myUserId = this.$store.state.auth.user.id
    console.log(`[EmailPlaceholderSettings] Found my user id: ${this.myUserId}`)
    this.root.userSettingsService.read$(this.myUserId).subscribe(
      (response) => {
        this.mySettings = response.data.userSetting
        console.log(`[EmailPlaceholderSettings] received my user settings:`, this.mySettings)
        this.fillFormFields(this.mySettings)
        this.handleShowLoading(false)
      },
      (err) => {
        console.error(`[EmailPlaceholderSettings] could not find my user settings:`, err.response)
        switch (err.response.status) {
          case 403:
            this.$router.push('/logout')
            break
          case 404:
            console.error(`[EmailPlaceholderSettings] user settings do not exist`)
            this.root.userSettingsService.create$({ userId: this.myUserId }).subscribe((createdResponse) => {
              this.mySettings = createdResponse.data.userSetting
              console.log(`[EmailPlaceholderSettings] received my NEW user settings:`, this.mySettings)
              this.fillFormFields(this.mySettings)
              this.handleShowLoading(false)
            })
            break

          default:
            break
        }
      }
    )
  }

  fillFormFields(data: any) {
    this.emailSenderName = data.emailSenderName
    this.emailSenderPosition = data.emailSenderPosition
    this.emailSenderReturnEmail = data.emailSenderReturnEmail
  }

  saveEmailPlaceholders() {
    this.saveEmailPlaceholdersLoading = true
    this.mySettings.emailSenderName = this.emailSenderName
    this.mySettings.emailSenderPosition = this.emailSenderPosition
    this.mySettings.emailSenderReturnEmail = this.emailSenderReturnEmail
    console.log(`[EmailPlaceholderSettings] save my user settings:`, this.mySettings)
    this.root.userSettingsService.update$(this.myUserId, this.mySettings).subscribe((response) => {
      console.log(`[EmailPlaceholderSettings] received response of saving my user settings:`, response)
      this.$nextTick(() => {
        this.saveEmailPlaceholdersLoading = false
      })
    })
  }

  testApplicationsCall() {
    this.root.applicationService.getTest$().subscribe((response) => {
      console.log(`[EmailPlaceholderSettings] received response of test:`, response)
    })
  }
}
