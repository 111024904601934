
import { Component, Vue } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { MpSelectIndex } from './MpSelect.interfaces'

import _ from 'lodash'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { map } from 'rxjs/operators'
import { DataTableHeader } from 'vuetify'

@Component({ components: {} })
export default class MpSelectComponent extends Vue {
  private root!: VueGevekom
  private search = ''
  moment = moment

  private mpSelectIndexServerData: MpSelectIndex[] = []
  private templatesIndex: any

  private snackMissingEmailTemplate = false
  private snackNoSelectedApplications = false

  private headers: DataTableHeader[] = [
    { text: '', value: 'selected', align: 'center', sortable: false },
    { text: 'Eingegangen', value: 'createdDate', align: 'start', class: 'created-date', sortable: false },
    {
      text: 'Nachname',
      value: 'lastName',
      class: 'lastName'
    },
    {
      text: 'Vorname',
      value: 'firstName',
      sortable: false
    },
    { text: 'Rufnummer', value: 'rufnummer' },
    { text: 'E-Mail', value: 'email' },
    { text: 'herkunft', value: 'herkunft' },
    { text: 'Standort', value: 'standort' },
    { text: 'Bewerbung als', value: 'applicationAs', class: 'dropdown-applicationAs', sortable: false },
    { text: 'Emailvorlage', value: 'emailTemplate', class: 'dropdown', sortable: false }
  ]

  created() {
    console.log('[MpSelectComponent] Created the MpSelectComponent class')
    this.root = this.$root as VueGevekom
    this.$store.commit('showProgressBar')
    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.applicationService.initService(tenantId, token)
    }
    if (!this.root.messengerService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.user.tokens.Messenger.tokens.messengerAPIToken

      this.root.messengerService.initService(tenantId, token)
    }
    if (!this.root.emailTemplatesService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.emailTemplatesService.initService(tenantId, token)
    }

    this.root.emailTemplatesService.emailTemplatesIndex$.subscribe((templatesIndex) => {
      console.log('[MpSelectComponent] Received templates index', templatesIndex)
      if (!templatesIndex) {
        this.root.emailTemplatesService.getIndex$().subscribe()
      } else if (templatesIndex) {
        const idx = _.filter(templatesIndex, { templateType: 'incomingReceiptAndTest' })
        this.root.$nextTick().then(() => {
          this.templatesIndex = idx || []
          console.log('[MpSelectComponent] filtered templates for dropdown', this.templatesIndex)
          // this.loadApplicationsFromServer()
          this.root.applicationService.getDataConfigs$().subscribe(
            (response: any) => {
              console.log('[MpSelectComponent] Get data configs from Server:', response)
              this.$store.commit('setConfigs', response?.data?.configs)
              this.loadApplicationsFromServer()
            },
            (err: any) => {
              console.error('[MpSelectComponent] ERROR:', err)
              this.$store.commit('hideProgressBar')
              switch (err.request.status) {
                case 403:
                  this.$router.push('/logout')
                  break

                default:
                  break
              }
            }
          )
        })
      }
    })

    if (!this.templatesIndex) {
      this.root.emailTemplatesService.getIndex$().subscribe()
    }
  }

  loadApplicationsFromServer() {
    this.root.messengerService
      .getIndexOfNotImported$()
      .pipe(
        map((response: AxiosResponse<any>) => {
          if (response?.data?.messages) {
            return response?.data?.messages
          }
          return []
        })
      )
      .subscribe((list: MpSelectIndex[]) => {
        console.log('[MpSelectComponent] received index from Server:', list)
        this.mpSelectIndexServerData = []
        list.forEach((item: MpSelectIndex) => {
          let firstName
          let lastName
          if (item.name?.length > 0) {
            firstName = item.name.split(' ')[0]
            const nameArray = item.name.split(' ')
            nameArray.splice(0, 1)
            lastName = nameArray.join(' ')
          } else {
            firstName = item.firstName || ''
            lastName = item.lastName || ''
          }
          this.mpSelectIndexServerData.push({
            ...item,
            selected: false,
            emailTemplate: null,
            applicationAs: 'agent',
            firstName,
            lastName
          })
        })
        // console.log('[MpSelectComponent] MP-Select-Liste:', this.mpSelectIndexServerData)
        this.$store.commit('hideProgressBar')
      })
  }

  processImportMessages() {
    console.log('[MpSelectComponent] data for processing import', this.mpSelectIndexServerData)
    const importMsgs: MpSelectIndex[] = []
    for (const msg of this.mpSelectIndexServerData) {
      if (msg.selected === true) {
        if (!msg.emailTemplate) {
          this.snackMissingEmailTemplate = true
          return
        }
        importMsgs.push(msg)
      }
    }
    console.log('[MpSelectComponent] messages for processing import on server', importMsgs)
    if (importMsgs.length > 0) {
      this.$store.commit('showProgressBar')
      this.root.messengerService.importMessages$(importMsgs).subscribe((response: any) => {
        console.log('[MpSelectComponent] response from server', response)
        this.loadApplicationsFromServer()
      })
    } else {
      this.snackNoSelectedApplications = true
    }
  }

  processHideMessages() {
    console.log('[MpSelectComponent] data for processing hide messages', this.mpSelectIndexServerData)
    const hideMsgs: MpSelectIndex[] = []
    for (const msg of this.mpSelectIndexServerData) {
      if (msg.selected === true) {
        hideMsgs.push(msg)
      }
    }
    console.log('[MpSelectComponent] messages for hiding on server', hideMsgs)
    if (hideMsgs.length > 0) {
      this.$store.commit('showProgressBar')
      this.root.messengerService.hideMessages$(hideMsgs).subscribe((response: any) => {
        console.log('[MpSelectComponent] response from server', response)
        this.loadApplicationsFromServer()
      })
    } else {
      this.snackNoSelectedApplications = true
    }
  }

  reloadMessages() {
    this.$store.commit('showProgressBar')
    this.mpSelectIndexServerData = []
    this.loadApplicationsFromServer()
  }
}
