
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class GkDatePicker extends Vue {
  // @Prop({ default: false }) readonly showDialog!: boolean
  @Prop({ default: 'Datum auswählen' }) readonly datePickerLabel!: string
  @Prop() readonly value!: any

  private openMenu = false

  private pickerDate!: string | null
  private inputDate!: string

  @Emit('input')
  emitVModel(value: any) {
    console.log('[GkDatePicker] input emit for v-model support:', value)
    return value
  }

  @Emit('cancelConfirm')
  cancelConfirm() {
    console.log('[GkDatePicker] Cancel confirm dialog clicked.')
  }

  @Emit('acceptConfirm')
  acceptConfirm() {
    console.log('[GkDatePicker] Accept confirm dialog clicked.')
  }

  @Watch('pickerDate')
  watchPickerDate(newValue: string) {
    console.log('[GkDatePicker] Watched pickerDate:', newValue)
    const testDate = moment(newValue)
    if (testDate.isValid()) {
      this.inputDate = this.parseDate(testDate.format('YYYY-MM-DD'))
      this.emitVModel(testDate.format('YYYY-MM-DD'))
    }
  }

  @Watch('inputDate')
  watchInputDate(newValue: string) {
    console.log('[GkDatePicker] Watched inputDate:', newValue)
    if (!newValue) {
      this.pickerDate = null
      this.emitVModel(null)
    }
    if (newValue?.length === 10) {
      const testDate = moment(newValue, 'DD.MM.YYYY')
      if (testDate.isValid()) {
        console.log('[GkDatePicker] Testdate:', testDate.format('YYYY-MM-DD'))
        this.pickerDate = testDate.format('YYYY-MM-DD')
      }
    }
  }

  constructor() {
    super()
    // console.log('[GkDatePicker] v-model:', this.value)
    if (this.value) {
      this.pickerDate = moment(new Date(this.value)).format('YYYY-MM-DD')
      this.inputDate = this.parseDate(this.value)
    } else {
      this.pickerDate = ''
      this.inputDate = ''
      // this.pickerDate = moment().format('YYYY-MM-DD')
      // this.inputDate = this.parseDate(this.pickerDate)
    }
  }

  clearedDatePickerInput() {
    console.log('[GkDatePicker] cleared inputDate.')
  }

  parseDate(date: string) {
    // console.log('[GkDatePicker] parse date:', date)
    const testDate = moment(date)
    if (testDate.isValid()) {
      return testDate.format('DD.MM.YYYY')
    }
    return ''
  }
}
