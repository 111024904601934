
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class JobAdverts extends Vue {
  created() {
    console.log('[JobAdverts] Created the JobAdverts class')
    window.open('https://gevekom.de/bewerbung/ausschreibung/', '_blank')
    setTimeout(() => {
      this.$router.push('/dashboard')
    }, 100)
  }
}
