
import { Component, Vue } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'

import _ from 'lodash'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { map } from 'rxjs/operators'
import { DataTableHeader } from 'vuetify'

@Component({ components: {} })
export default class FormApplicationsContent extends Vue {
  private root!: VueGevekom
  private search = ''
  moment = moment

  private formApplicationsServerData: any[] = []
  private templatesIndex: any
  private defaultEmailTemplate: any = null

  private snackMissingEmailTemplate = false
  private snackNoSelectedApplications = false

  private checkEmailList: string[] = [];

  private headers: DataTableHeader[] = [
    { text: '', value: 'selected', align: 'center', sortable: false },
    { text: 'Eingegangen', value: 'createdDate', align: 'start', class: 'created-date', sortable: false },
    {
      text: 'Nachname',
      value: 'lastName',
      class: 'lastName'
    },
    {
      text: 'Vorname',
      value: 'firstName',
      sortable: false
    },
    { text: 'Rufnummer', value: 'phone' },
    { text: 'E-Mail', value: 'email' },
    { text: 'Standort', value: 'branch' },
    { text: 'Bewerbungskanal', value: 'applicationChannel', sortable: true },
    { text: 'Bewerbung als', value: 'applicationAs', class: 'dropdown-applicationAs', sortable: false },
    { text: 'Emailvorlage', value: 'emailTemplate', class: 'dropdown', sortable: false }
  ]

  created() {
    console.log('[FormApplicationsComponent] Created the FormApplicationsComponent class')
    this.root = this.$root as VueGevekom
    this.$store.commit('showProgressBar')
    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.applicationService.initService(tenantId, token)
    }
    if (!this.root.messengerService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.user.tokens.Messenger.tokens.messengerAPIToken

      this.root.messengerService.initService(tenantId, token)
    }
    if (!this.root.emailTemplatesService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.emailTemplatesService.initService(tenantId, token)
    }

    this.root.emailTemplatesService.emailTemplatesIndex$.subscribe((templatesIndex) => {
      console.log('[FormApplicationsComponent] Received templates index', templatesIndex)
      if (!templatesIndex) {
        this.root.emailTemplatesService.getIndex$().subscribe()
      } else if (templatesIndex) {
        const idx = _.filter(templatesIndex, { templateType: 'incomingReceiptAndTest' })
        this.root.$nextTick().then(() => {
          this.templatesIndex = idx || []
          console.log('[FormApplicationsComponent] filtered templates for dropdown', this.templatesIndex)
          // this.loadApplicationsFromServer()
          this.root.applicationService.getDataConfigs$().subscribe(
            (response: any) => {
              console.log('[FormApplicationsComponent] Get data configs from Server:', response)
              this.$store.commit('setConfigs', response?.data?.configs)
              this.loadApplicationsFromServer()
            },
            (err: any) => {
              console.error('[FormApplicationsComponent] ERROR:', err)
              this.$store.commit('hideProgressBar')
              switch (err.request.status) {
                case 403:
                  this.$router.push('/logout')
                  break

                default:
                  break
              }
            }
          )
        })
      }
    })

    if (!this.templatesIndex) {
      this.root.emailTemplatesService.getIndex$().subscribe()
    }
  }

  loadApplicationsFromServer() {
    this.root.applicationService
      .getFormApplicationsIndex$()
      .pipe(
        map((response: AxiosResponse<any>) => {
          if (response?.data?.applicationsEmailList) {
            this.checkEmailList = []
            for (const email of response.data.applicationsEmailList as string[]) {
              this.checkEmailList.push(email.toLowerCase())
            }
            // this.checkEmailList.push('max1@mustermann1.de')
          }
          if (response?.data?.formApplications) {
            return response?.data?.formApplications
          }
          return []
        })
      )
      .subscribe((list: any[]) => {
        console.log('[FormApplicationsComponent] received index from Server:', list)
        // console.log('[FormApplicationsContent] received list:', this.checkEmailList)
        this.formApplicationsServerData = []
        list.forEach((item: any) => {
          this.formApplicationsServerData.push({
            ...item,
            selected: false,
            emailTemplate: null,
            applicationAs: 'agent'
          })
        })
        // console.log('[FormApplicationsComponent] MP-Select-Liste:', this.formApplicationsServerData)
        this.$store.commit('hideProgressBar')
      })
  }

  reloadMessages() {
    this.$store.commit('showProgressBar')
    this.formApplicationsServerData = []
    this.loadApplicationsFromServer()
  }

  processImportMessages() {
    console.log('[FormApplicationsComponent] processImportMessages:', this.formApplicationsServerData)
    const importApplications = []
    for (const application of this.formApplicationsServerData) {
      if (application.selected === true) {
        if (!application.emailTemplate) {
          if (this.defaultEmailTemplate) {
            console.log('[FormApplicationsComponent] set default email template', this.defaultEmailTemplate)
            application.emailTemplate = this.defaultEmailTemplate
          } else {
            this.snackMissingEmailTemplate = true
            return
          }
        }
        importApplications.push(application)
      }
    }
    this.$store.commit('showProgressBar')
    this.root.applicationService.transferToApplications$(importApplications).subscribe(
      (response: any) => {
        console.log('[FormApplicationsComponent] transferToApplications$:', response)
        this.defaultEmailTemplate = null
        this.$store.commit('hideProgressBar')
        this.reloadMessages()
      },
      (err: any) => {
        console.error('[FormApplicationsComponent] ERROR:', err)
        this.$store.commit('hideProgressBar')
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
    console.log('[FormApplicationsComponent] applications to process:', importApplications)
  }

  processHideMessages() {
    console.log('[FormApplicationsComponent] processHideMessages:', this.formApplicationsServerData)
    const rejectApplications = []
    for (const application of this.formApplicationsServerData) {
      if (application.selected === true) {
        rejectApplications.push(application)
      }
    }
    this.$store.commit('showProgressBar')
    this.root.applicationService.rejectApplications$(rejectApplications).subscribe(
      (response: any) => {
        console.log('[FormApplicationsComponent] rejectApplications$:', response)
        this.$store.commit('hideProgressBar')
        this.reloadMessages()
      },
      (err: any) => {
        console.error('[FormApplicationsComponent] ERROR:', err)
        this.$store.commit('hideProgressBar')
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
    console.log('[FormApplicationsComponent] applications to reject:', rejectApplications)
  }

  rowClasses(item: any) {
    console.log('[FormApplicationsContent] check item for double email:', item)
    if (this.checkEmailList.includes(item.email.toLowerCase())) {
      return 'possible-double-email'
    }
  }
}
