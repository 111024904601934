
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class ConfirmDialog extends Vue {
  @Prop({ default: false }) readonly showDialog!: boolean
  @Prop({ default: 'Den Vorgang wirklich durchführen?' }) readonly headline!: string
  @Prop({ default: 'Das kann nicht rückgängig gemacht werden!' }) readonly confirmationQuestion!: string
  @Prop({ default: 'Abbrechen' }) readonly cancelButtonLabel!: string
  @Prop({ default: 'Zustimmen' }) readonly confirmButtonLabel!: string

  @Emit('cancelConfirm')
  cancelConfirm() {
    console.log('[ConfirmDialog] Cancel confirm dialog clicked.')
  }

  @Emit('acceptConfirm')
  acceptConfirm() {
    console.log('[ConfirmDialog] Accept confirm dialog clicked.')
  }
}
