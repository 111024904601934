
import { Vue, Component } from 'vue-property-decorator'
import MassMailingComponent from '@/components/massmailing/MassMailing.vue'
import MassMailingHeader from '@/components/massmailing/Header.vue'

@Component({
  components: {
    MassMailingHeader,
    MassMailingComponent
  }
})
export default class MassMailing extends Vue {}
