
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TestComponent extends Vue {
  private title = 'Bewerbungseingänge'
  private user = null

  get lodashTest() {
    return this._.chunk(['a', 'b', 'c', 'd'], 2)
  }

  incrementStore() {
    this.$store.commit('increment')
    console.log('[Applications] Store value:', this.$store.state.count)
  }

  testHTTP() {
    this.axios
      .get(`/api/auth/v1/tenants/${this.$store.state.auth.tokenDictionary.Recruiting.tenantId}/test`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken}`,
          'content-type': 'application/json;charset=utf-8'
        }
      })
      .then((response) => {
        console.log('[Applications] HTTP Test response:', response.data)
      })
  }

  testRootHTTP() {
    this.axios
      .get(`/api/auth/v1/tenants/${this.$store.state.auth.tokenDictionary.Recruiting.tenantId}/test`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.tokenDictionary.Recruiting.tokens.rootAPIToken}`,
          'content-type': 'application/json;charset=utf-8'
        }
      })
      .then((response) => {
        console.log('[Applications] HTTP Test response:', response.data)
      })
  }
}
