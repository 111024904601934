
import { Vue, Component } from 'vue-property-decorator'
import MpSelectHeader from '@/components/mp-select/Header.vue'
import MpSelectComponent from '@/components/mp-select/MpSelect.vue'

@Component({
  components: {
    MpSelectHeader,
    MpSelectComponent
  }
})
export default class MpSelect extends Vue {}
