
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { Observable } from 'rxjs'
import GkDatePicker from '@/components/DatePicker.vue'
import _ from 'lodash'
import moment from 'moment'

interface TableItem {
  yearSelect: string
  branch: string
  applicationsCount: number
  cols?: { [key: string]: number }
}

@Component({ components: { GkDatePicker } })
export default class StatusFeedback extends Vue {
  private root!: VueGevekom

  private branchesList: any[] = []
  private statusList: any[] = []
  private list: any[] = []
  private yearSelect: string | number = '2021'
  private yearsForSelect = ['2021']

  @Watch('yearSelect')
  watchStatDate(newValue: any, oldValue: any) {
    console.log('[StatusFeedback] Watched yearSelect NEW:', newValue)
    console.log('[StatusFeedback] Watched yearSelect OLD:', oldValue)
  }

  private tableItems: TableItem[] = []
  private tableSumRow: TableItem | null = null
  private tableHeaders = [
    {
      text: 'Jahr',
      align: 'start',
      value: 'dayDate',
      sortable: false
    },
    { text: 'Standort', value: 'branch' },
    { text: 'Bewerbungen', value: 'applicationsCount', align: 'center' }
  ]

  private search = ''

  @Prop({ default: null }) readonly applicationsList!: Observable<any[]>
  @Prop({ default: null }) readonly branches!: Observable<any[]>

  get statisticsDate() {
    return this.yearSelect
  }

  created() {
    console.log('[StatusFeedback] IS CREATED')
    this.root = this.$root as VueGevekom
    this.yearsForSelect = []
    for (let year = 2021; year <= moment().year(); year++) {
      this.yearsForSelect.push(year.toString())
    }
    this.yearSelect = new Date().getFullYear().toString()

    this.statusList = this.$store.state.company.configs.status
    console.log('[StatusFeedback] status list:', this.statusList)
    for (const status of this.statusList) {
      this.tableHeaders.push({ text: status.displayText, value: status.value })
    }
    this.branches.subscribe((branches) => {
      this.branchesList = _.orderBy(branches, ['displayText'])
    })
  }

  handleDateChange(event: any) {
    console.log('[StatusFeedback] handle change of statistics date:', event)
  }

  createItemsList(showYear?: string | number) {
    this.$store.commit('showProgressBar')
    console.log('[StatusFeedback] create new data table items list for year:', showYear)
    console.log('[StatusFeedback] branches list:', this.branchesList)

    const newList: TableItem[] = []
    const newSumRow: any = {
      yearSelect: this.yearSelect,
      branch: 'Summen:',
      applicationsCount: 0
    }

    for (const status of this.statusList) {
      newSumRow[status.value] = 0
    }
    console.log('[StatusFeedback] newSumRow:', newSumRow)

    const lApplications = this.list
    console.log('[StatusFeedback] list of applications:', lApplications)

    for (const b of this.branchesList) {
      const newRow: any = {
        yearSelect: this.yearSelect,
        branch: b.displayText,
        applicationsCount: 0
      }
      for (const status of this.statusList) {
        newRow[status.value] = 0
      }
      for (const a of lApplications) {
        if (a.branch === b.value) {
          newRow.applicationsCount += 1
          newRow[a.status] += 1
          newSumRow[a.status] += 1
        }
      }
      newList.push(newRow)
      newSumRow.applicationsCount += newRow.applicationsCount
    }
    // newList.push(newSumRow)
    this.tableSumRow = newSumRow
    console.log('[StatusFeedback] tableSumRow:', this.tableSumRow)

    this.tableItems = newList
    console.log('[StatusFeedback] new table items list:', this.tableItems)

    this.$store.commit('hideProgressBar')
  }

  loadItemsFromServer(year: any) {
    console.log(`[loadItemsFromServer] load items list from server for year:`, year)
    this.root.applicationService.getIndex$({ year: year }).subscribe(
      (response: any) => {
        console.log('[Statistics] Get applications index from Server:', response)
        this.$store.commit('setIncomingApplications', response.data.applications)
        this.$store.commit('hideProgressBar')
        this.list = response.data.applications
        this.createItemsList(year)
      },
      (err: any) => {
        console.error('[Statistics] ERROR:', err)
        this.$store.commit('setIncomingApplications', [])
        // this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        // this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
        this.list = []
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
  }

  loadStatisticsForSelectedYear() {
    this.$store.commit('showProgressBar')
    console.log('[StatusFeedback] load statistics for selected year:', this.yearSelect)
    this.root.applicationService.getStatisticsIndex$(`${this.yearSelect}`).subscribe((response: any) => {
      console.log('[StatusFeedback] Get applications index from Server:', response)
      this.list = response.applications
      this.createItemsList(this.yearSelect)
      this.$store.commit('hideProgressBar')
    })
  }
}
