
import { Vue, Component } from 'vue-property-decorator'
import EmailPlaceholderSettings from './general/email-placeholders.vue'

@Component({ components: { EmailPlaceholderSettings } })
export default class GeneralSettings extends Vue {
  private loading = false
  private panel: any = null
  private settingOpen = false

  handleShowLoading(evt: boolean) {
    this.loading = evt || false
  }

  handlePanelChange() {
    console.log(`[GeneralSettings] handle panel change:`, this.panel)
    if (!this.settingOpen) {
      this.loading = true
      this.settingOpen = true
    }
  }
}
