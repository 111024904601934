import { StatusType } from '@/appInterfaces/applications.interfaces'
import { ConfigKeys } from '@/components/settings/settings.interfaces'
import { BehaviorSubject, defer, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
// import moment from 'moment'
import http from '../http-common'

import store from '@/store'
import { Tile } from './rights.singleton.service'
import moment from 'moment'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export interface ApplicationComment {
  id?: string
  commentedAt?: string
  commenterName: string
  commenterLogin: string
  commenterId: string
  comment: string
  rating: number
}

export interface ApplicationGetIndexOptions {
  day?: string
  yearMonth?: string
  year?: string
  quartal?: string
}

export interface IndexForMassMailingOptions {
  branches?: string[] | null
  status?: string
  applicationReceivedAtSince?: string
  applicationReceivedAtUntil?: string
  hiredAt?: string
  lastName?: string
  firstName?: string
  applicationAs?: string
  applicationsChannel?: string
}

class ApplicationsSingletonService {
  private _isInited = false
  private _tenantId!: string
  private _token!: string
  private headersBasic: Record<string, any> = {}

  private statusConfig: BehaviorSubject<StatusType[]> = new BehaviorSubject<StatusType[]>([])

  public testSubject: BehaviorSubject<string> = new BehaviorSubject('')

  private applicationsForCommenting!: BehaviorSubject<any[]>
  public applicationsForCommenting$!: Observable<any[]>
  public searchString = ''

  get isInited(): boolean {
    return this._isInited
  }

  get statusConfig$(): Observable<StatusType[]> {
    return this.statusConfig.asObservable()
  }

  get token(): string {
    return this._token
  }

  get tenantId(): string {
    return this._tenantId
  }

  initService(tenantId: string, token: string, overwrite = false) {
    console.log('[ApplicationsSingletonService] store state:', store.state)
    if (!this._isInited || overwrite) {
      this._tenantId = tenantId
      this._token = token
      this.headersBasic = {
        headers: {
          Authorization: `Bearer ${this._token}`
        }
      }
      this.applicationsForCommenting = new BehaviorSubject<any[]>([])
      this.applicationsForCommenting$ = this.applicationsForCommenting.asObservable()
      this._isInited = true
    }
  }

  getTest$(): Observable<any> {
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/test`, this.headersBasic))
  }

  getDataConfigs$(): Observable<any> {
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/dataConfigs`, this.headersBasic)).pipe(
      map((cfgs: any) => {
        console.log('[ApplicationsSingletonService] received map of data config:', cfgs)
        if (cfgs.data?.configs?.status) {
          this.statusConfig.next(cfgs.data.configs.status)
        } else {
          this.statusConfig.next([])
        }
        return cfgs
      })
    )
  }

  getSkillsList$(): Observable<any> {
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/skillsList`, this.headersBasic)).pipe(
      map((cfgs: any) => {
        console.log('[ApplicationsSingletonService] received skills list:', cfgs)
        // if (cfgs.data?.skillsList) {
        //   this.statusConfig.next(cfgs.data.configs.status)
        // } else {
        //   this.statusConfig.next([])
        // }
        return cfgs
      })
    )
  }

  updateDataConfig$(configKey: string, newConfig: ConfigKeys): Observable<any> {
    const payload = {
      config: configKey,
      newConfig
    }
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/dataConfigs`, payload, this.headersBasic))
  }

  getIndex$(options?: ApplicationGetIndexOptions) {
    const config: AxiosRequestConfig = { ...this.headersBasic }

    if (options) {
      config.params = options
    }

    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/`, config))
  }

  getDailyStatsIndex$(day: string) {
    const config: AxiosRequestConfig = { ...this.headersBasic }

    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/statistics/dailyFeedback/${day}`, config))
  }

  getWeeklyStatsIndex$(weekStartDate: string) {
    const config: AxiosRequestConfig = { ...this.headersBasic }

    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/statistics/weeklyFeedback/${weekStartDate}`, config))
  }

  getMonthlyStatsIndex$(yearMonth: string) {
    const config: AxiosRequestConfig = { ...this.headersBasic }

    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/statistics/monthlyFeedback/${yearMonth}`, config))
  }

  getTableIndex$(timeRange?: string, jobType?: string, searchString?: string) {
    console.log('[ApplicationsSingletonService] Get table index for time range:', timeRange)
    console.log('[ApplicationsSingletonService] Get table index for job type:', jobType)
    console.log('[ApplicationsSingletonService] Get table index for search string:', searchString)
    let sinceDate: string

    switch (timeRange) {
      case 'last-three-month':
        sinceDate = moment().subtract(3, 'months').toDate().toISOString()
        break
      case 'last-six-month':
        sinceDate = moment().subtract(6, 'months').toDate().toISOString()
        break
      case 'last-twelce-month':
        sinceDate = moment().subtract(12, 'months').toDate().toISOString()
        break
      case 'all':
        sinceDate = new Date(1900, 0, 1).toISOString()
        break

      default:
        sinceDate = moment().subtract(1, 'month').toDate().toISOString()
        break
    }

    return defer(() =>
      http.get(`/api/applications/v1/tenants/${this._tenantId}/tableIndex`, {
        ...this.headersBasic,
        params: {
          since: sinceDate,
          jobType: jobType?.toLowerCase() !== 'agent' ? 'fk' : 'agent',
          searchString
        }
      })
    )
  }

  getStatisticsIndex$(year: string) {
    const config: AxiosRequestConfig = { ...this.headersBasic }

    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/statistics/${year}`, config)).pipe(
      map((response) => {
        console.log('[ApplicationsSingletonService] received statistics index:', response)
        return (response && response.data) || null
      })
    )
  }

  getIndexForMassMailing$(options?: IndexForMassMailingOptions) {
    console.log('[getIndexForMassMailing] Get table index with options:', options)

    return defer(() =>
      http.post(`/api/applications/v1/tenants/${this._tenantId}/indexForMassMailing`, options, {
        ...this.headersBasic
      })
    )
  }

  massMailing$(options?: any) {
    console.log('[massMailing] Post massMailing with options:', options)

    return defer(() =>
      http.post(`/api/applications/v1/tenants/${this._tenantId}/massMailing`, options, {
        ...this.headersBasic
      })
    )
  }

  getApplicationsForCommenting$(userId: string): Observable<any> {
    const self = this
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/commentApplications/${userId}`, this.headersBasic)).pipe(
      map((response: any) => {
        const applications = response.data?.applications
        self.applicationsForCommenting.next([...applications])
        return [...applications]
      })
    )
  }

  get$(id: string): Observable<any> {
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/${id}`, this.headersBasic))
  }

  create$(data: any): Observable<any> {
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/`, data, this.headersBasic))
  }

  update$(id: string, data: any): Observable<any> {
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/${id}`, data, this.headersBasic))
  }

  delete$(id: string): Observable<any> {
    return defer(() => http.delete(`/api/applications/v1/tenants/${this._tenantId}/${id}`, this.headersBasic))
  }

  blacklistEmailInApplicationWithId$(id: string): Observable<any> {
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/${id}/blacklistEmail`, {}, this.headersBasic))
  }

  unblacklistEmailInApplicationWithEmail$(email: string): Observable<any> {
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/blackListed/unblacklistEmail`, { email }, this.headersBasic))
  }

  setIsNotADuplicateApplication$(id: string): Observable<any> {
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/${id}/setIsNotADuplicateApplication`, {}, this.headersBasic))
  }

  sendEmail$(applicationId: string, emailTemplateId: string): Observable<any> {
    const payload = {
      emailTemplateId
    }
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/send-email/${applicationId}`, payload, this.headersBasic))
  }

  isAdmin(): boolean {
    return store.state.auth.viewRights?.admin || false
  }

  isAdminFK(): boolean {
    return store.state.auth.viewRights?.adminFK || false
  }

  isAdminAgents(): boolean {
    return store.state.auth.viewRights?.adminAgents || false
  }

  uploadApplicationDocument$(applicationId: string, title: string, docBase64: string, size: number): Observable<any> {
    const payload = {
      docTitle: title,
      documentBase64: docBase64,
      size
    }
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/${applicationId}/document/`, payload, this.headersBasic))
  }

  uploadApplicationAvatar$(applicationId: string, title: string, docBase64: string, size: number): Observable<any> {
    const payload = {
      docTitle: title,
      avatarBase64: docBase64,
      size
    }
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/${applicationId}/avatar/`, payload, this.headersBasic))
  }

  getApplicationDocument$(applicationId: string, documentId: string | null): Observable<any> {
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/${applicationId}/document/${documentId}`, this.headersBasic))
  }

  deleteApplicationDocument$(applicationId: string, documentId: string): Observable<any> {
    return defer(() => http.delete(`/api/applications/v1/tenants/${this._tenantId}/${applicationId}/document/${documentId}`, this.headersBasic))
  }

  getApplicationChannelsStats$(rangeStart?: string, rangeEnd?: string) {
    const reqCfg = {
      ...this.headersBasic,
      params: {
        rangeStart,
        rangeEnd
      }
    }
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/getApplicationChannelsStats`, reqCfg))
  }

  getRecruiterStats$(rangeStart?: string, rangeEnd?: string) {
    const reqCfg = {
      ...this.headersBasic,
      params: {
        rangeStart,
        rangeEnd
      }
    }
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/getRecruiterStats`, reqCfg))
  }

  getDashboardTiles$() {
    return of<Tile[]>([
      { title: 'Bewerbungen', src: '/applications', flex: 3, icon: 'mdi-account-multiple', right: 'applications' },
      { title: 'MessengerPeople', src: '/mp-select', flex: 3, icon: 'mdi-database-import', right: 'messengerPeople' },
      { title: 'Online-Bewerbungen', src: '/form-applications', flex: 3, icon: 'mdi-account-multiple', right: 'formApplications' },
      { title: 'Stellenanzeige', src: '/job-ad', flex: 3, icon: 'mdi-account-check', right: 'formApplications' },
      { title: 'Statistik', src: '/statistics', flex: 3, icon: 'mdi-chart-box-outline', right: 'statistics' },
      { title: 'Massenmailing', src: '/massmailing', flex: 3, icon: 'mdi-email-outline', right: 'applications' },
      { title: 'Exporte', src: '/exports', flex: 3, icon: 'mdi-database-export', right: 'exports' },
      { title: 'Einstellungen', src: '/settings', flex: 3, icon: 'mdi-account-multiple', right: 'settings' }
    ])
  }

  addCommentToApplication$(
    id: string,
    comment: {
      comment: string
      rating: number
    }
  ): Observable<any> {
    return defer(() => http.post(`/api/applications/v1/tenants/${this._tenantId}/${id}/addComment`, comment, this.headersBasic))
  }

  getCheckDoubleEmailApplications$(): Observable<any> {
    return defer(() => http.get(`/api/applications/v1/tenants/${this._tenantId}/checkDoubleEmailApplications`, this.headersBasic)).pipe(
      map((response: AxiosResponse) => {
        return response.data.doubles
      })
    )
  }

  /**
   * Ab hier die Aufrufe für die Arbeit mit den Online Bewerbungen
   */

  getFormApplicationsIndex$(): Observable<any> {
    return defer(() => http.get(`/api/form-applications/v1/tenants/${this._tenantId}/`, this.headersBasic))
  }

  transferToApplications$(formApplications: any[]): Observable<any> {
    return defer(() => http.post(`/api/form-applications/v1/tenants/${this._tenantId}/transferToApplications`, formApplications, this.headersBasic))
  }

  rejectApplications$(formApplications: any[]): Observable<any> {
    return defer(() => http.post(`/api/form-applications/v1/tenants/${this._tenantId}/rejectApplications`, formApplications, this.headersBasic))
  }

  importCsvFormApplications$(csvText: string): Observable<any> {
    return defer(() => http.post(`/api/form-applications/v1/tenants/${this._tenantId}/importCsv`, { csv: csvText }, this.headersBasic))
  }
}

export default ApplicationsSingletonService
