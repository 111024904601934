
import { Component, Vue } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'

import FilterRow from '@/components/exports/FilterRow.vue'
import moment from 'moment'

@Component({ components: { FilterRow } })
export default class ExportsComponent extends Vue {
  private root!: VueGevekom

  private loadingApplications = false

  private filteredData: any[] = []

  private exportFieldLabels = {
    applicationReceipt: 'Bewerbung_erhalten_am',
    applicationReceiptConfirmedAt: 'Eingangsbestätigung_am',
    temporaryWorker: 'Zeitarbeiter',
    temporaryWorkerCompany: 'Zeitarbeitsfirme',
    directBrokerWorker: 'Direktvermittlerarbeiter',
    directBrokerCompany: 'DV_Firma',
    sex: 'Geschlecht',
    firstName: 'Vorname',
    lastName: 'Nachname',
    phone: 'Telefon',
    email: 'EMail',
    birthday: 'Geburtstag',
    applicationAs: 'Bewerbung als',
    branch: 'Standort',
    applicationChannel: 'Bewerbungskanal',
    applicationChannelEmployee: 'geworben von',
    project: 'Projekt',
    comments: 'Kommentar',
    acknowledgementOfApplicationSentAt: 'Eingangsbestätigung_versandt_am',
    waitingList: 'Warteliste',
    feedbackTest: 'Rückmeldung_Test',
    languageSkill: 'Sprachfähigkeiten',
    writingSkill: 'Schreibfähigkeiten',
    possibleWorktime: 'Mögliche_Arbeitszeiten',
    possibleShiftWork: 'Schichtarbeit_möglich',
    possibleWeekendWork: 'Wochenendarbeit_möglich',
    possibleJobEntry: 'möglicher_Arbeitsstart',
    hasActualAnJob: 'ist_Beschäftigt',
    hasDisability: 'hat_Schwerbeschädigung',
    disabilityPercent: 'Schwerbeschädigung_%',
    invitedToJobInterviewAt: 'Eingeladen_zum_Vorstellungsgespräch_am',
    feedbackJobInterviewAt: 'Rückmeldung_Vorstellungsgesspräch_am',
    decision: 'Entscheidung',
    hired: 'Eingestellt',
    hiredDecisionMadeAt: 'Einstellungs-Entscheidung_getroffen _am',
    hiringDate: 'Einstellungsdatum',
    rejected: 'Abgelehnt',
    rejectedReason: 'Ablehnungsgrund',
    waitingListIntermediateNote: 'Warteliste_Zwischenbescheid',
    status: 'Status',
    emailCC: 'E-mail CC',
    birthplace: 'Geburtsort',
    nativeLanguage: 'Muttersprachen',
    fluentLanguage: 'Gute bis sehr gute Sprachkenntnisse',
    basicLanguage: 'Basis Sprachkenntnisse',
    requirementHO: 'HO möglich',
    speedTest: 'Speedtest',
    videoAssessment: 'Video Vorstellung',
    street: 'Strasse',
    houseNr: 'Hausnr.',
    zip: 'PLZ',
    city: 'Ort',
    country: 'Land',
    skills: 'Fähigkeiten',
    recruiter: 'Recruiter',
    blacklistedEmail: 'Email ist blacklisted',
    id: 'Bewerber-ID',
    salaryRequest: 'Gehaltsvorstellung'
  }

  private exportFields = [
    'applicationReceipt',
    'applicationReceiptConfirmedAt',
    'temporaryWorker',
    'temporaryWorkerCompany',
    'directBrokerWorker',
    'directBrokerCompany',
    'sex',
    'firstName',
    'lastName',
    'street',
    'houseNr',
    'zip',
    'city',
    'country',
    'phone',
    'email',
    'emailCC',
    'birthday',
    'birthplace',
    'degree',
    'applicationAs',
    'branch',
    'applicationChannel',
    'applicationChannelEmployee',
    'project',
    'comments',
    'acknowledgementOfApplicationSentAt',
    'waitingList',
    'feedbackTest',
    'languageSkill',
    'writingSkill',
    'nativeLanguage',
    'fluentLanguage',
    'basicLanguage',
    'possibleWorktime',
    'requirementHO',
    'speedTest',
    'possibleShiftWork',
    'possibleWeekendWork',
    'possibleJobEntry',
    'hasActualAnJob',
    'hasDisability',
    'disabilityPercent',
    'invitedToJobInterviewAt',
    'feedbackJobInterviewAt',
    'videoAssessment',
    'decision',
    'hired',
    'hiredDecisionMadeAt',
    'hiringDate',
    'rejected',
    'rejectedReason',
    'waitingListIntermediateNote',
    'status',
    'skills',
    'recruiter',
    'blacklistedEmail',
    'id',
    'salaryRequest'
  ]

  created() {
    console.log('[ExportsComponent] Created the ExportsComponent class')
    this.root = this.$root as VueGevekom
    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.applicationService.initService(tenantId, token)
    }
    this.loadApplicationsFromServer()
  }

  loadApplicationsFromServer() {
    this.root.applicationService.getDataConfigs$().subscribe(
      (response: any) => {
        console.log('[ExportsComponent] Get data configs from Server:', response)
        this.$store.commit('setConfigs', response?.data?.configs)
      },
      (err: any) => {
        console.error('[ExportsComponent] ERROR:', err)
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
  }

  doExportTest() {
    console.log('[ExportsComponent] Do the export test download')
  }

  private receivedFilteredData(filteredData: any[]) {
    this.filteredData = filteredData
  }

  private getExportName(): string {
    const date = moment().format('YYYY-MM-DD_HH-mm-ss')
    return `${date}-Bewerbungen.csv`
  }

  private clearFilteredData() {
    this.filteredData = []
  }
}
