
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
// import moment from 'moment'
import _ from 'lodash'
import ApplicationsSingletonService from '@/appServices/applications.singleton.service'
import { VueGevekom } from '../applications/Form.interfaces'

@Component({ components: {} })
export default class ExecuteRow extends Vue {
  private root!: VueGevekom

  @Prop({ default: null }) readonly applicationService!: ApplicationsSingletonService
  @Prop({ default: null }) readonly receivedApplications!: any[]

  private template = ''
  private templatesIndex: any[] = []

  private snackNoSelectedEmailTemplate = false

  @Watch('template')
  onTemplateChanged(newValue: string[], oldValue: string[]) {
    console.log('[Execute] Email template selection changed:', newValue)
    console.log('[Execute] Email template selection changed oldValue:', oldValue)
    console.log('[Execute] Email template selection changed v-model:', this.template)
  }

  @Emit('sendEmailsNow')
  sendEmailsNow() {
    if (this.template && this.template.length > 0) {
      console.log('[MassMailingComponent -> Execute] emit send email with template id:', this.template)
      return this.template
    }
    this.snackNoSelectedEmailTemplate = true
  }

  created() {
    console.log('[MassMailingComponent -> Executed] created:', this.applicationService)
    this.root = this.$root as VueGevekom

    this.root.emailTemplatesService.emailTemplatesIndex$.subscribe((templatesIndex) => {
      console.log('[MpSelectComponent] Received templates index', templatesIndex)
      if (!templatesIndex) {
        this.root.emailTemplatesService.getIndex$().subscribe()
      } else if (templatesIndex) {
        const idx = _.orderBy(templatesIndex, ['name']) // _.filter(templatesIndex, { templateType: 'incomingReceiptAndTest' })
        this.root.$nextTick().then(() => {
          this.templatesIndex = idx || []
          console.log('[MpSelectComponent] filtered templates for dropdown', this.templatesIndex)
          // this.loadApplicationsFromServer()
          this.root.applicationService.getDataConfigs$().subscribe(
            (response: any) => {
              console.log('[MpSelectComponent] Get data configs from Server:', response)
              this.$store.commit('setConfigs', response?.data?.configs)
            },
            (err: any) => {
              console.error('[MpSelectComponent] ERROR:', err)
              this.$store.commit('hideProgressBar')
              switch (err.request.status) {
                case 403:
                  this.$router.push('/logout')
                  break

                default:
                  break
              }
            }
          )
        })
      }
    })

    if (!this.templatesIndex) {
      this.root.emailTemplatesService.getIndex$().subscribe()
    }
  }

  hideSendEmailButton() {
    // console.log(`[Execute] Check for hiding the send button`)
    if (!this.receivedApplications) {
      return true
    }
    if (!this.template && this.template.length === 0) {
      return true
    }

    let selectedCount = 0
    for (const application of this.receivedApplications) {
      if (application.isSelected) {
        selectedCount++
      }
    }
    return selectedCount === 0
  }
}
