
import { Component, Vue } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
// import FilterRow from './FilterRow.vue'

import FilterRow from '@/components/massmailing/FilterRow.vue'
import moment from 'moment'
import _ from 'lodash'
import { DataTableHeader } from 'vuetify'
import ExecuteRow from './Execute.vue'

@Component({ components: { FilterRow, ExecuteRow } })
export default class MassMailingComponent extends Vue {
  private root!: VueGevekom

  private loadingApplications = false

  private exportFields: string[] = []

  moment = moment
  private search = ''
  private receivedApplications: any[] = []

  private headers: DataTableHeader[] = [
    // { text: '', value: 'selected', align: 'center', sortable: false },
    { text: 'Eingegangen', value: 'createdDate', align: 'start', class: 'created-date', sortable: false },
    {
      text: 'Nachname',
      value: 'lastName',
      class: 'lastName'
    },
    {
      text: 'Vorname',
      value: 'firstName',
      sortable: false
    },
    { text: 'E-Mail', value: 'email' },
    { text: 'Standort', value: 'branch' },
    { text: 'Bewerbung als', value: 'applicationAs', class: 'dropdown-applicationAs', sortable: false },
    { text: 'Kanal', value: 'applicationChannel' }
  ]

  created() {
    console.log('[ExportsComponent] Created the MassMailingComponent class')
    this.root = this.$root as VueGevekom
    const d = moment()
    console.log(d)
  }

  private receivedFilteredData(filteredData: any[]) {
    console.log(`[MassMailingComponent] Received filtered data:`, filteredData)
    const newApplications = []
    for (const application of filteredData) {
      application.select = false
      newApplications.push({ ...application })
    }
    this.receivedApplications = newApplications
  }

  private getJobName(applicationAs: string): string {
    return _.find(this.$store.state.company.configs.jobs, { value: applicationAs }).displayText
  }

  private getApplicationChannelName(applicationChannel: string): string {
    return _.find(this.$store.state.company.configs.applicationsChannels, { value: applicationChannel })?.displayText || ''
  }

  private toggleAllReceivedApplications(evt: { items: any[]; value: any }) {
    console.log(`[MassMailingComponent] toggle all received applications selection for:`, evt)
    const itemIds = []

    for (const item of evt.items) {
      itemIds.push(item.id)
    }
    console.log('itemIds:', itemIds)

    const newApplications = []
    for (const application of this.receivedApplications) {
      if (itemIds.includes(application.id)) {
        application.select = evt.value
        application.isSelected = evt.value
      }
      newApplications.push({ ...application })
    }
    this.receivedApplications = newApplications
  }

  private toggleItemReceivedApplications(evt: { item: any; value: any }) {
    console.log(`[MassMailingComponent] toggle item received applications selection for:`, evt)

    const newApplications = []
    for (const application of this.receivedApplications) {
      if (evt.item.id === application.id) {
        application.select = evt.value
        application.isSelected = evt.value
      }
      newApplications.push({ ...application })
    }
    this.receivedApplications = newApplications
  }

  clickedOnRow(row: any) {
    console.log(`[MassMailingComponent] clicked on row:`, row)
  }

  private sendEmailsNow(templateId: string) {
    this.$store.commit('showProgressBar')
    console.log(`[MassMailingComponent] received send email now with template id:`, templateId)
    const emailReceivers = []
    for (const application of this.receivedApplications) {
      if (application.isSelected) {
        emailReceivers.push(application)
      }
    }
    this.root.applicationService
      .massMailing$({
        emailTemplateId: templateId,
        emailReceivers
      })
      .subscribe((response: any) => {
        console.log(`[MassMailingComponent] received response from massMailing:`, response.data)
        this.receivedApplications = []
        this.$store.commit('hideProgressBar')
      })
  }
}
