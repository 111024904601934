
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Authorize extends Vue {
  created() {
    // Lifecycle Event when component created and ready for use
    // debugger;
    console.log('[Authorize] lifecycle created event:', this)
    console.log('[Authorize] Use route params to authorize and go to dashboard, when successfull')
    console.log('[Authorize] Use this token:', this.$route.query.code)
    this.axios
      .post('/api/auth/v1/tenants/79d15315/authorize', {
        params: { code: this.$route.query.code }
      })
      .then((response) => {
        console.log('[Application] posting to authorize now!')
        if (response.data.user) {
          const user = response.data.user
          this.$store.commit('setUser', user) // .setUser(this.user);
          if (user.modulesDictionary) {
            this.$store.commit('setTokenDictionary', user.modulesDictionary)
          } else {
            this.$store.commit('setTokenDictionary', {})
          }
          // this.$router.replace('/applications')
          this.$router.replace('/dashboard')
        } else {
          this.$router.replace('/login')
        }
      })
  }
}
