
import { Vue, Component } from 'vue-property-decorator'
import { VueGevekom } from '../applications/Form.interfaces'
import { GetEmailTemplateTypesResponse, GetEmailTemplatesResponse, EmailTemplateTypes, EmailTemplate } from '@/appInterfaces/emailTemplates.interfaces'
import EmailTemplatesTable from '@/components/settings/email-templates/TemplatesTable.vue'
import EmailTemplateForm from '@/components/settings/email-templates/Form.vue'
import { Observable } from 'rxjs'
import { StatusType } from '@/appInterfaces/applications.interfaces'

@Component({ components: { EmailTemplatesTable, EmailTemplateForm } })
export default class EmailTemplateSettings extends Vue {
  private root!: VueGevekom

  private showComponent = 'table'

  private statusTypes: StatusType[] = []
  private templateTypes: EmailTemplateTypes = {}
  private emailTemplates: EmailTemplate[] = []
  private emailTemplates$!: Observable<any>

  private emailTemplateEditData!: any

  private confirmDeleteDialog = false
  private confirmDeleteId!: string | null

  created() {
    console.log('[EmailTemplateSettings] Created, now initing component.')
    this.root = this.$root as VueGevekom
    this.emailTemplates$ = this.root.emailTemplatesService.emailTemplatesIndex$

    if (!this.root.emailTemplatesService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.emailTemplatesService.initService(tenantId, token)
    }
    this.root.applicationService.statusConfig$.subscribe((status) => {
      console.log('[EmailTemplateSettings] Received status types:', status)
      this.statusTypes = status
    })
    this.emailTemplates$.subscribe((idx: EmailTemplate[]) => {
      console.log('[EmailTemplateSettings] Received email templates index from observable:', idx)
      this.root.$nextTick().then(() => {
        this.emailTemplates = idx
      })
    })
    this.loadEmailTemplatesFromServer()
  }

  loadEmailTemplatesFromServer() {
    this.root.emailTemplatesService.getEmailTemplateTypes$().subscribe((templateTypesResponse: GetEmailTemplateTypesResponse) => {
      console.log('[EmailTemplateSettings] Received Email Template types from server:', templateTypesResponse.data.emailTemplateTypes)
      this.templateTypes = templateTypesResponse.data.emailTemplateTypes
    })
    this.root.emailTemplatesService.getIndex$().subscribe()
    /**
     .subscribe((response: GetEmailTemplatesResponse) => {
      console.log('[EmailTemplateSettings] Received Email Templates from server:', response.data.emailTemplates)
      this.emailTemplates = response.data.emailTemplates
    })
     */
  }

  reloadEmailTemplates() {
    this.loadEmailTemplatesFromServer()
  }

  addNewEmailTemplate() {
    console.log('[EmailTemplateSettings] Add new email template')
    this.emailTemplateEditData = {
      templateType: this.templateTypes.ReceptionEmail,
      name: 'Neue Vorlage',
      subject: '',
      emailBody: ''
    }
    this.showComponent = 'form'
  }

  editEmailTemplate(evt: any) {
    console.log('[EmailTemplateSettings] Edit email template:', evt)
    this.root.emailTemplatesService.read$(evt.id).subscribe((response: any) => {
      console.log('[EmailTemplateForm] Received Email Template from server:', response)
      this.emailTemplateEditData = response.data.emailTemplate
      this.showComponent = 'form'
    })
  }

  saveFormEdit() {
    const editData: any = (this.$refs.emailTemplateForm as VueGevekom).getActualFormData
    console.log('[EmailTemplateSettings] Save new email template')
    console.log('[EmailTemplateSettings] Save new email template data:', editData)

    if (editData?.id) {
      console.log('[EmailTemplateSettings] save for an edit')
      this.root.emailTemplatesService.update$(editData.id, editData).subscribe((response) => {
        console.log('[EmailTemplateSettings] response from save for edit:', response)

        this.root.emailTemplatesService.getIndex$().subscribe((response: GetEmailTemplatesResponse) => {
          console.log('[EmailTemplateSettings] Received Email Templates from server:', response.data.emailTemplates)
          this.emailTemplates = response.data.emailTemplates
        })
      })
    } else {
      console.log('[EmailTemplateSettings] save for an create')
      this.root.emailTemplatesService.create$(editData).subscribe((response) => {
        console.log('[EmailTemplateSettings] response from save for create:', response)

        this.root.emailTemplatesService.getIndex$().subscribe((response: GetEmailTemplatesResponse) => {
          console.log('[EmailTemplateSettings] Received Email Templates from server:', response.data.emailTemplates)
          this.emailTemplates = response.data.emailTemplates
        })
      })
    }

    this.emailTemplateEditData = {}
    this.showComponent = 'table'
  }

  cancelFormEdit() {
    this.emailTemplateEditData = {}
    this.showComponent = 'table'
  }

  deleteTemplate(evt: any) {
    console.log('[EmailTemplateSettings] Delete Email Template from server:', evt)
    if (evt.id) {
      this.confirmDeleteId = evt.id
      this.confirmDeleteDialog = true
    }
  }

  deleteThisEmailTemplate() {
    console.log('[EmailTemplateSettings] Delete Email Template from server confirmed:', this.confirmDeleteId)
    this.root.emailTemplatesService.delete$(this.confirmDeleteId || '').subscribe((response: any) => {
      console.log('[EmailTemplateSettings] Received response for deleting email template from server:', response)
      this.root.emailTemplatesService.getIndex$().subscribe((response: GetEmailTemplatesResponse) => {
        console.log('[EmailTemplateSettings] Received Email Templates from server:', response.data.emailTemplates)
        this.emailTemplates = response.data.emailTemplates
      })
    })
    this.confirmDeleteId = null
    this.confirmDeleteDialog = false
    this.showComponent = 'table'
  }
}
