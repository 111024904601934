
import { Vue, Component } from 'vue-property-decorator'
import SettingsMain from '@/components/settings/Main.vue'

@Component({
  components: {
    SettingsMain
  }
})
export default class Settings extends Vue {
  private title = 'Einstellungen'
  private user = null
}
