
import { Component, Vue } from 'vue-property-decorator'

import TestComponent from '@/components/TestComponent.vue'
import ApplicationsHeader from '@/components/applications/Header.vue'
import ApplicationsTable from '@/components/applications/ApplicationsTable.vue'
import ActionsRow from '@/components/applications/ActionsRow.vue'
import ApplicationsForm from '@/components/applications/Form.vue'
import ApplicationsCommentForm from '@/components/applications/CommentsForm.vue'
import ApplicationDuplicatesCheck from '@/components/applications/ApplicationDuplicates.vue'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { ErrorSnackData } from '@/appInterfaces/applications.interfaces'
import { forkJoin } from 'rxjs'

@Component({
  components: {
    TestComponent,
    ApplicationsHeader,
    ApplicationsTable,
    ActionsRow,
    ApplicationsForm,
    ApplicationsCommentForm,
    ApplicationDuplicatesCheck
  }
})
export default class Applications extends Vue {
  private root!: VueGevekom
  private showErrorSnack = false
  private errorSnackData: ErrorSnackData = {
    status: 500,
    errorMessage: '',
    timeout: 6000
  }

  private showTable = true
  private showAddForm = false
  private showEditForm = false
  private showCommentForm = false
  private showDuplicatesCheck = false

  private isFormValid = false
  private errorSnack = false
  private errorSnackText = ''

  private editData: any

  private confirmDeleteDialog = false
  private confirmBlacklistDialog = false
  private deleteApplicationId = ''
  private blacklistApplicationItem: any = null

  private searchString = ''
  private selectedSection = 'Agent'
  private selectedTimeRange = 'last-month'
  created() {
    console.log('[Applications] Created the Applications class')
    this.root = this.$root as VueGevekom

    if (!this.root.userSettingsService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.userSettingsService.initService(tenantId, token)
    }
    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.applicationService.initService(tenantId, token)
    }
    if (!this.root.emailTemplatesService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.emailTemplatesService.initService(tenantId, token)
    }

    this.loadApplicationsFromServerForInit()
  }

  isFormValidEmitted(val: any) {
    console.log('[Applications] Received new value for isFormValid:', val)
    this.isFormValid = val
  }

  loadApplicationsFromServerForInit() {
    this.$store.commit('showProgressBar')
    const getSubs = forkJoin({
      dataConfigs: this.root.applicationService.getDataConfigs$(),
      emailTemplates: this.root.emailTemplatesService.getIndex$(),
      // applicationsIndex: this.root.applicationService.getTableIndex$(this.selectedTimeRange, this.selectedSection),
      skillsList: this.root.applicationService.getSkillsList$()
    })

    getSubs.subscribe(
      // (response: { dataConfigs: any; emailTemplates: any; applicationsIndex: any; skillsList: any }) => {
      (response: { dataConfigs: any; emailTemplates: any; skillsList: any }) => {
        console.log('[Applications] received result of forkJoin requests for INIT:', response)
        if (response.dataConfigs) {
          this.$store.commit('setConfigs', response.dataConfigs.data?.configs)
        }
        // if (response.applicationsIndex) {
        //   this.$store.commit('setIncomingApplications', response.applicationsIndex.data.applications)
        // }
        if (response.skillsList) {
          this.$store.commit('setSkills', response.skillsList.data?.skillsList)
        }
        this.$store.commit('hideProgressBar')
      },
      (err: any) => {
        console.error('[Applications] ERROR:', err)
        this.$store.commit('setIncomingApplications', [])
        this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
  }

  loadApplicationsFromServer() {
    this.$store.commit('showProgressBar')
    this.$store.commit('setIncomingApplications', [])
    const getSubs = forkJoin({
      applicationsIndex: this.root.applicationService.getTableIndex$(this.selectedTimeRange, this.selectedSection, this.searchString)
    })

    getSubs.subscribe(
      (response: { applicationsIndex: any }) => {
        console.log('[Applications] received result of forkJoin requests:', response)
        if (response.applicationsIndex) {
          this.$store.commit('setIncomingApplications', response.applicationsIndex.data.applications)
        }
        this.$store.commit('hideProgressBar')
      },
      (err: any) => {
        console.error('[Applications] ERROR:', err)
        this.$store.commit('setIncomingApplications', [])
        this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
      }
    )
  }

  reloadApplications() {
    console.log('[Applications] Reload applications requested.')
    this.loadApplicationsFromServer()
  }

  addNewApplication() {
    console.log('[Applications] Add a new application.')
    this.showTable = false
    this.showEditForm = false
    this.showAddForm = true
    this.showCommentForm = false
  }

  checkDoubleEmailApplications() {
    console.log('[Applications] checkDoubleEmailApplications.')
    this.showTable = false
    this.showEditForm = false
    this.showAddForm = false
    this.showCommentForm = false
    this.showDuplicatesCheck = true
  }

  exitDuplicatesCheck() {
    console.log('[Applications] exitDuplicatesCheck.')
    this.showTable = true
    this.showEditForm = false
    this.showAddForm = false
    this.showCommentForm = false
    this.showDuplicatesCheck = false
  }

  onSectionChanged(section: string) {
    console.log('[Applications] Switch to this section:', section)
    if (section && section.length > 0) {
      this.selectedSection = section
      // this.$store.commit('showProgressBar')
      // this.loadApplicationsFromServer()
    }
  }

  onTimeRangeChanged(range: string) {
    console.log('[Applications] Switch to this time range:', range)
    if (range && range.length > 0) {
      this.selectedTimeRange = range
      // this.$store.commit('showProgressBar')
      // this.loadApplicationsFromServer()
    }
  }

  onChangedFilterString(searchString: string) {
    // console.log('[Applications] received new search string:', searchString)
    if (searchString && searchString.length > 0) {
      this.searchString = searchString
    } else {
      this.searchString = ''
    }
  }

  editApplication(application: any) {
    console.log('[Applications] Edit this application:', application)
    if (application?.id) {
      this.root.applicationService.get$(application.id).subscribe(
        (app: any) => {
          if (app?.data?.application) {
            this.editData = app.data.application
            this.showTable = false
            this.showAddForm = false
            this.showEditForm = true
            this.showCommentForm = false
          }
        },
        (err: any) => {
          console.error('[Applications] ERROR:', err)
          this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
          this.showErrorSnack = true
          this.$store.commit('hideProgressBar')
          switch (err.request.status) {
            case 403:
              this.$router.push('/logout')
              break

            default:
              break
          }
        }
      )
    }
  }

  commentApplication(application: any) {
    console.log('[Applications] Comment this application:', application)
    if (application?.id) {
      this.root.applicationService.get$(application.id).subscribe(
        (app: any) => {
          if (app?.data?.application) {
            this.editData = app.data.application
            this.showTable = false
            this.showAddForm = false
            this.showEditForm = false
            this.showCommentForm = true
          }
        },
        (err: any) => {
          console.error('[Applications] ERROR:', err)
          this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
          this.showErrorSnack = true
          this.$store.commit('hideProgressBar')
          switch (err.request.status) {
            case 403:
              this.$router.push('/logout')
              break

            default:
              break
          }
        }
      )
    }
  }

  deleteApplication(application: any) {
    console.log('[Applications] Delete this application:', application)
    this.deleteApplicationId = application.id
    this.confirmDeleteDialog = true
  }

  blacklistApplicationEmail(application: any) {
    console.log('[Applications] blacklist this application:', application)
    this.blacklistApplicationItem = application
    this.confirmBlacklistDialog = true
  }

  deleteThisApplication() {
    this.$store.commit('showProgressBar')
    this.root.applicationService.delete$(this.deleteApplicationId).subscribe(
      (response: any) => {
        console.log('[Applications] Response for deleting an application:', response)
        this.deleteApplicationId = ''
        this.confirmDeleteDialog = false
        this.loadApplicationsFromServer()
        this.showAddForm = false
        this.showEditForm = false
        this.showTable = true
        this.showCommentForm = false
        this.$store.commit('hideProgressBar')
      },
      (err: any) => {
        console.error('[Applications] ERROR:', err)
        this.deleteApplicationId = ''
        this.confirmDeleteDialog = false
        this.showAddForm = false
        this.showEditForm = false
        this.showTable = true
        this.showCommentForm = false
        this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
      }
    )
  }

  blacklistThisApplication() {
    console.log('[Applications] blacklist the email in this application id:', this.blacklistApplicationItem)
    this.$store.commit('showProgressBar')
    this.root.applicationService.blacklistEmailInApplicationWithId$(this.blacklistApplicationItem.id).subscribe(
      (response: any) => {
        console.log('[Applications] Response of blacklisting email in an application:', response)
        this.blacklistApplicationItem.blacklistedEmail = true
        // this.blacklistApplicationItem = null
        this.confirmBlacklistDialog = false
        // this.loadApplicationsFromServer()
        this.showAddForm = false
        this.showEditForm = false
        this.showTable = true
        this.showCommentForm = false
        this.$store.commit('hideProgressBar')
      },
      (err: any) => {
        console.error('[Applications] ERROR:', err)
        this.blacklistApplicationItem = null
        this.confirmBlacklistDialog = false
        this.showAddForm = false
        this.showEditForm = false
        this.showTable = true
        this.showCommentForm = false
        this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
      }
    )
  }

  unblacklistThisApplication(application: any) {
    console.log('[Applications] unblacklist the email in this application id:', application)
    this.$store.commit('showProgressBar')
    this.root.applicationService.unblacklistEmailInApplicationWithEmail$(application.email).subscribe(
      (response: any) => {
        console.log('[Applications] Response of unblacklisting email in an application:', response)
        // this.loadApplicationsFromServer()
        application.blacklistedEmail = false
        this.showAddForm = false
        this.showEditForm = false
        this.showTable = true
        this.showCommentForm = false
        this.$store.commit('hideProgressBar')
      },
      (err: any) => {
        console.error('[Applications] ERROR:', err)
        this.showAddForm = false
        this.showEditForm = false
        this.showTable = true
        this.showCommentForm = false
        this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
        this.showErrorSnack = true
        this.$store.commit('hideProgressBar')
      }
    )
  }

  saveEditApplication(): void {
    if (this.showCommentForm) {
      console.log('[Applications] Save commented application.')
      this.loadApplicationsFromServer()
      this.showAddForm = false
      this.showEditForm = false
      this.showTable = true
      this.showCommentForm = false
      this.$store.commit('hideProgressBar')
    } else {
      console.log('[Applications] Save new/edited application.')
      if (!(this.$refs.applicationsForm as any).isFormValid()) {
        this.errorSnackText = 'Das Formular ist noch nicht korrekt ausgefüllt. Prüfe bitte die rot gekennzeichneten Felder'
        this.errorSnack = true
        return
      }
      if (this.showAddForm) {
        this.$store.commit('showProgressBar')
        const createData: any = (this.$refs.applicationsForm as VueGevekom).getActualFormData
        if (!createData.documents) {
          createData.documents = []
        }
        console.log('[Applications] Test of ref access to forms child:', createData)
        this.root.applicationService.create$(createData).subscribe(
          (response: any) => {
            console.log('[Applications] Response for creating a new application:', response)
            this.loadApplicationsFromServer()
            this.showAddForm = false
            this.showEditForm = false
            this.showTable = true
            this.showCommentForm = false
            this.$store.commit('hideProgressBar')
          },
          (err: any) => {
            console.error('[Applications] ERROR:', err)
            this.showAddForm = false
            this.showEditForm = false
            this.showTable = true
            this.showCommentForm = false
            this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
            this.showErrorSnack = true
            this.$store.commit('hideProgressBar')
          }
        )
      } else if (this.showEditForm) {
        this.$store.commit('showProgressBar')
        const createData: any = (this.$refs.applicationsForm as VueGevekom).getActualFormData
        if (!createData.documents) {
          createData.documents = []
        }
        console.log('[Applications] Test of ref access to forms child:', createData)
        this.root.applicationService.update$(createData.id, createData).subscribe(
          (response: any) => {
            console.log('[Applications] Response for updating an application:', response)
            this.loadApplicationsFromServer()
            this.showAddForm = false
            this.showEditForm = false
            this.showTable = true
            this.showCommentForm = false
            // this.$store.commit('hideProgressBar')
          },
          (err: any) => {
            console.error('[Applications] ERROR:', err)
            this.showAddForm = false
            this.showEditForm = false
            this.showTable = true
            this.showCommentForm = false
            this.errorSnackData = { ...this.errorSnackData, status: err.request.status, errorMessage: err.message }
            this.showErrorSnack = true
            this.$store.commit('hideProgressBar')
          }
        )
      }
    }
  }

  cancelEditApplication() {
    const createData: any = (this.$refs.applicationsForm as VueGevekom).getActualFormData
    console.log('[Applications] Cancel add/edit of application:', this.editData)
    console.log('[Applications] createData:', createData)
    this.showAddForm = false
    this.showEditForm = false
    this.showTable = true
    this.showCommentForm = false
    if (createData.blacklistedEmail !== this.editData.blacklistedEmail) {
      this.loadApplicationsFromServer()
    }
  }

  addedDocumentToApplications(doc: any[]) {
    this.editData.documents = doc
  }
}
