
import { Component, Emit, Vue } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import moment from 'moment'
import _ from 'lodash'

@Component({ components: {} })
export default class DashboardCommentApplicationsComponent extends Vue {
  private root!: VueGevekom

  private commentApps: any[] = []
  private moment = moment

  @Emit('commentApp')
  commentApp(application: any): any {
    // console.log('[DashboardCommentApplicationsComponent] Comment this application:', application)
    // this.$emit('comment-app', application)
    return application
  }

  created() {
    console.log('[DashboardCommentApplicationsComponent] Created the DashboardCommentApplicationsComponent class')
    this.root = this.$root as VueGevekom
    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.applicationService.initService(tenantId, token)
    }
    this.root.applicationService.applicationsForCommenting$.subscribe((apps: any[]) => {
      console.log('[DashboardCommentApplicationsComponent] Received new apps for comments:', apps)
      this.commentApps = _.orderBy(apps, ['createdDate'], ['desc'])
    })
  }

  getStatusDisplayText(statusValue: string) {
    // console.log('[ApplicationsTable] Find displayText for status:', statusValue)
    const status = _.find(this.$store.state.company.configs.status, { value: statusValue })
    return status ? status.displayText : ''
  }

  getJobDisplayText(jobValue: string) {
    // console.log('[ApplicationsTable] Find displayText for job:', jobValue)
    const status = _.find(this.$store.state.company.configs.jobs, { value: jobValue })
    return status ? status.displayText : ''
  }
}
