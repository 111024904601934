
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { DataTableHeader } from 'vuetify'
import _ from 'lodash'

@Component
export default class ApplicationsTable extends Vue {
  $root: any

  @Prop({ default: 'Agent' }) readonly sectionToShow!: string
  @Prop({ default: 'last-month' }) readonly timeRangeToShow!: string

  private search = ''

  private dialog = false
  private dialogDelete = false
  private editedIndex = -1
  private editedItem = {}
  private defaultItem = {}

  private headers = [
    {
      text: 'Erfasst am',
      align: 'start',
      value: 'createdDate'
    },
    // {
    //   text: 'Eingang',
    //   align: 'start',
    //   value: 'applicationReceipt'
    // },
    { text: 'Status', value: 'status' },
    { text: 'Name', value: 'lastName' },
    { text: 'Vorname', value: 'firstName' },
    { text: 'Standort', value: 'branch' },
    { text: 'Bewerbung als', value: 'applicationAs' },
    // { text: 'Geburtstag', value: 'birthday' },
    { text: 'Telefon', value: 'phone', class: 'phone-col' },
    { text: 'Email', value: 'email', class: 'email-col' },
    { text: 'Actions', value: 'actions', sortable: false, class: 'action-buttons' }
  ]

  private logCount = 0

  private evaluatedApplications: any = []

  moment = moment

  @Emit('reloadApplications')
  reloadApplications() {
    console.log('[Applications -> ApplicationsTable] reload Applications for applications list.')
  }

  @Watch('sectionToShow')
  onSectionToShowChange() {
    console.log('[ApplicationsTable] show this kind of section in table:', this.sectionToShow)
    this.evaluatedApplications = this.incomingApplications()
  }

  @Watch('timeRangeToShow')
  onTimeRangeToShowChange() {
    console.log('[ApplicationsTable] show this time range in table:', this.timeRangeToShow)
    // this.evaluatedApplications = this.incomingApplications()
  }

  @Watch('search')
  onSearchChange() {
    // console.log('[ApplicationsTable] changed search string:', this.search)
    this.changedFilterString(this.search)
    this.$root.applicationService.searchString = this.search
  }

  incomingApplications() {
    const evaluatedApplications = []
    const applicationsInStore = this.$store.state.applications.incomingApplications
    const filteredApplications = applicationsInStore || []

    for (const application of filteredApplications) {
      const cd = moment(application.createdDate).format('DD.MM.YYYY HH:mm')
      const status = _.find(this.$store.state.company.configs.status, { value: application.status })
      const job = _.find(this.$store.state.company.configs.jobs, { value: application.applicationAs })
      evaluatedApplications.push({
        ...application,
        createdDate: cd,
        filterString: `${application.firstName || ''}${application.lastName || ''}_${application.lastName || ''}${application.firstName || ''}${status ? status.displayText : ''
          }${job ? job.displayText : ''}`.toLowerCase(),
        statusDisplayText: status ? status.displayText : '',
        jobDisplayText: job ? job.displayText : ''
      })
    }
    return evaluatedApplications
  }

  get formTitle() {
    return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
  }

  @Emit('changedFilterString')
  changedFilterString(searchString: any) {
    // console.log('[ApplicationsTable] changed string in filter:', searchString)
    // console.log('[ApplicationsTable] Edit this item with id:', props)
    return searchString
  }

  @Emit('editApplication')
  editItem(item: any) {
    console.log('[ApplicationsTable] Edit this item:', item)
    // console.log('[ApplicationsTable] Edit this item with id:', props)
    return item
  }

  @Emit('commentApplication')
  commentItem(item: any) {
    console.log('[ApplicationsTable] Comment this item:', item)
    // console.log('[ApplicationsTable] Edit this item with id:', props)
    return item
  }

  @Emit('deleteApplication')
  deleteItem(item: any) {
    console.log('[ApplicationsTable] Delete this item:', item)
    return item
  }

  @Emit('blacklistApplicationEmail')
  blacklistItem(item: any) {
    console.log('[ApplicationsTable] blacklist the email of this item:', item)
    return item
  }

  @Emit('unblacklistApplicationEmail')
  unblacklistItem(item: any) {
    console.log('[ApplicationsTable] unblacklist the email of this item:', item)
    return item
  }

  created() {
    console.log('[ApplicationsTable] Create called!')

    // Reset search string to last value saved in singleton
    this.search = this.$root.applicationService.searchString || ''

    // this.evaluatedApplications = this.incomingApplications
    this.$store.subscribe((mutation, state) => {
      // console.log('[ApplicationsTable] mutation:', mutation)
      console.log('[ApplicationsTable] state:', state)
      if (mutation.type === 'setIncomingApplications') {
        this.evaluatedApplications = this.incomingApplications()
      }
    })
    if (this.evaluatedApplications.length === 0) {
      this.evaluatedApplications = this.incomingApplications()
    }
  }

  customFilter(value: any, search: string, item: any): boolean {
    if (this.logCount++ < 10) {
      console.log('[ApplicationsTable] filter value:', value)
      console.log('[ApplicationsTable] search value:', search)
      console.log('[ApplicationsTable] item value:', item)
    }
    if (value != null && search != null && typeof value === 'string') {
      if (moment(value).isValid()) {
        value = moment(value).format('DD.MM.YYYY')
      }
      // const status = _.find(this.$store.state.company.configs.status, { value })
      // if (status) {
      //   value = status.displayText
      // }
      // const job = _.find(this.$store.state.company.configs.jobs, { value })
      // if (job) {
      //   value = job.displayText
      // }
      return value.toLowerCase().includes(search.toLowerCase()) || item.filterString.includes(search.toLowerCase())
    }
    return false
  }

  editRow(
    item: any,
    options: {
      expand: (value: boolean) => void
      headers: DataTableHeader[]
      isExpanded: boolean
      isMobile: boolean
      isSelected: boolean
      item: any
      select: (value: boolean) => void
    }
  ) {
    console.log('[ApplicationsTable] Edit row with item:', item)
    console.log('[ApplicationsTable] Edit row with item:', options)
    this.editItem(item)
  }

  keyupSearchTextField(evt: KeyboardEvent) {
    // console.log('[ApplicationsTable] pressed a key:', evt)
    if (evt.key === 'Enter') {
      // console.log('[ApplicationsTable] pressed Enter, we should search!')
      this.reloadApplications()
    }
  }

  deleteItemConfirm() {
    console.log('[ApplicationsTable] Close confirmed delete dialog')
  }

  close() {
    console.log('[ApplicationsTable] Close this dialog')
  }

  closeDelete() {
    console.log('[ApplicationsTable] Close delete dialog')
  }

  save() {
    console.log('[ApplicationsTable] Save ')
  }

  getStatusDisplayText(statusValue: string) {
    // console.log('[ApplicationsTable] Find displayText for status:', statusValue)
    const status = _.find(this.$store.state.company.configs.status, { value: statusValue })
    return status ? status.displayText : ''
  }

  getBranchDisplayText(branchValue: string) {
    // console.log('[ApplicationsTable] Find displayText for branch:', branchValue)
    const status = _.find(this.$store.state.company.configs.branches, { value: branchValue })
    return status ? status.displayText : ''
  }

  getJobDisplayText(jobValue: string) {
    // console.log('[ApplicationsTable] Find displayText for job:', jobValue)
    const status = _.find(this.$store.state.company.configs.jobs, { value: jobValue })
    return status ? status.displayText : ''
  }

  testEmail(item: any) {
    console.log('[ApplicationsTable] send test email for application:', item)
    this.$root.applicationService.sendEmail$(item.id, 'f2dbd7df-164b-d7a0-0ce4-fb68c8675c21').subscribe((response: any) => {
      console.log('[ApplicationsTable] Response from send test email for application:', response)
    })
  }
}
