/* eslint-disable @typescript-eslint/no-explicit-any */

import { TokensDictionary } from './store.interfaces'

export interface StateAuth {
  tenantId: string | null
  isAuthenticated: boolean
  tokenDictionary: TokensDictionary
  user: any
  lang: string
  viewRights: { [key: string]: boolean }
}

export interface StateApp {
  showSidebar: boolean
  showProgressBar: boolean
}

export interface StateApplications {
  incomingApplications: any[]
  rejectedApplications: any[]
  acceptedApplications: any[]
  skillsList: any[]
}

export interface StateCompanyConfigItem {
  displayText: string
  value: string
}

export interface StateCompany {
  roles: any[]
  configs: { [key: string]: StateCompanyConfigItem[] }
}
export interface State {
  count: number
  auth: StateAuth
  app: StateApp
  applications: StateApplications
  company: StateCompany
}

const getInitialState = (): State => {
  return {
    count: 0,
    auth: {
      tenantId: null,
      isAuthenticated: false,
      tokenDictionary: {},
      user: null,
      lang: 'de',
      viewRights: {}
    },
    app: {
      showSidebar: true,
      showProgressBar: true
    },
    applications: {
      incomingApplications: [],
      rejectedApplications: [],
      acceptedApplications: [],
      skillsList: []
    },
    company: {
      roles: [],
      configs: {}
    }
  }
}

export default getInitialState
