
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { EmailTemplateTypes, PropertyPlaceholder } from '@/appInterfaces/emailTemplates.interfaces'

import { StatusType } from '@/appInterfaces/applications.interfaces'
import { VueGevekom } from '@/components/applications/Form.interfaces'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { MyImageUploadAdapterPlugin } from './imageUploadAdapter'

interface TemplateType {
  displayText: string
  value: string
}

@Component({ components: { ClassicEditor } })
export default class EmailTemplateForm extends Vue {
  $clipboard: any

  @Prop() readonly originalData!: any
  @Prop() readonly templateTypes!: EmailTemplateTypes
  @Prop() readonly statusTypes!: StatusType[]
  @Prop({ default: '' }) readonly tenantId!: string
  @Prop({ default: '' }) readonly token!: string

  @Emit('isFormValid')
  isFormValid() {
    return (this.$refs.form as HTMLFormElement).validate()
  }

  private root!: VueGevekom

  private valid = true
  private nameRules = [(v: any) => !!v || 'Name is required', (v: any) => (v && v.length <= 10) || 'Name must be less than 10 characters']

  private emailRules = [(v: any) => !!v || 'E-mail is required', (v: any) => /.+@.+\..+/.test(v) || 'E-mail must be valid']

  private dataSet: any
  private types: TemplateType[] = []

  private previewEmailText = false

  private propertyPlaceholders: PropertyPlaceholder[] = []
  private propertyPlaceHolder = ''

  private vueEditorContent = ''
  private ckeditor: any
  private editorConfig = {
    extraPlugins: [MyImageUploadAdapterPlugin],
    imageUpload: {
      tenantId: this.tenantId,
      token: this.token
    },
    image: {
      styles: ['alignLeft', 'alignCenter', 'alignRight'],
      resizeOptions: [
        {
          name: 'imageResize:original',
          label: 'Original',
          value: null
        },
        {
          name: 'imageResize:50',
          label: '50%',
          value: '50'
        },
        {
          name: 'imageResize:75',
          label: '75%',
          value: '75'
        }
      ],
      toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', '|', 'imageTextAlternative']
    }
  }

  get getActualFormData() {
    this.dataSet.emailBody = this.getEditorContent()
    return this.dataSet
  }

  getEditorContent() {
    const style = `
    <style>
    .image.image_resized {
      display: block;
      box-sizing: border-box;
    }

    .image.image_resized img {
        width: 100%;
    }

    .image.image_resized > figcaption {
        display: inline-block;
    }
    </style>
    `
    return `${style}${this.vueEditorContent}` // .replace(/<p><br><\/p>/g, '')
  }

  created() {
    console.log('[EmailTemplateForm] Created the form component:', this.originalData)
    console.log('[EmailTemplateForm] received template types:', this.templateTypes)
    this.root = this.$root as VueGevekom
    this.ckeditor = ClassicEditor

    this.propertyPlaceHolder = ''
    this.propertyPlaceholders = this.root.emailTemplatesService.emailTemplatePlaceholders
    for (const key of Object.keys(this.templateTypes)) {
      this.types.push({ value: this.templateTypes[key], displayText: key })
    }

    let tmp = { ...this.originalData }
    tmp = this.checkFormData(tmp)
    this.dataSet = tmp
    setTimeout(() => {
      this.vueEditorContent = this.dataSet.emailBody
    }, 100)
    console.log('[EmailTemplateForm] work with this dataSet:', this.dataSet)
  }

  parseDate(date: string) {
    console.log('[EmailTemplateForm] parse date:', date)
    return date ? moment(new Date(date)).format('DD.MM.YYYY') : ''
  }

  validate() {
    // this.form.validate()
  }

  reset() {
    // this.form.reset()
    console.log('[EmailTemplateForm] Reset')
  }

  resetValidation() {
    // this.form.resetValidation()
  }

  checkFormData(data: any) {
    return data
  }

  changedDefault(evt: any) {
    console.log('[EmailTemplateForm] changed default checkbox:', evt)
  }

  copyToClipboard() {
    this.$clipboard(`@@${this.propertyPlaceHolder}`)
  }

  appendToEmail() {
    this.vueEditorContent += `@@${this.propertyPlaceHolder}`
    this.dataSet.emailBody = this.vueEditorContent
  }

  public handleImageAdded(file: File, Editor: any, cursorLocation: any, resetUploader?: any) {
    // console.log('[EmailTemplateForm] upload image to server for vue2-editor:', file)
    // console.log('[EmailTemplateForm] upload image to server for vue2-editor:', Editor)
    // console.log('[EmailTemplateForm] upload image to server for vue2-editor:', cursorLocation)
    console.log('[EmailTemplateForm] upload image to server for vue2-editor:', resetUploader)
    const reader = new FileReader()

    reader.onload = (e) => {
      console.log('[EmailTemplateForm] file readed:', e)
      const payload = {
        imageData: e.target?.result,
        imageName: new Date().getTime().toString()
      }
      this.root.emailTemplatesService.uploadImage$(payload).subscribe((response: any) => {
        console.log('[EmailTemplateForm] response from image upload:', response)
        const url = response.data.imageUrl
        Editor.insertEmbed(cursorLocation, 'image', url)
      })
    }
    reader.readAsDataURL(file)
  }

  public handleImageRemoved(image: any) {
    console.log('[EmailTemplateForm] remove image from server for vue2-editor:', image)
  }
}
