import { defer, Observable } from 'rxjs'
import http from '../http-common'

class MessengerSingletonService {
  private _isInited = false
  private headersBasic: Record<string, any> = {}

  public tenantId!: string
  public token!: string

  get isInited(): boolean {
    return this._isInited
  }

  initService(tenantId: string, token: string, overwrite = false) {
    if (!this._isInited || overwrite) {
      this.tenantId = tenantId
      this.token = token
      this.headersBasic = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
      this._isInited = true
    }
  }

  getTest$(): Observable<any> {
    return defer(() => http.get(`/api/messenger/v1/tenants/${this.tenantId}/test`, this.headersBasic))
  }

  getIndexOfNotImported$() {
    return defer(() => http.get(`/api/messenger/v1/tenants/${this.tenantId}/indexNotImported`, this.headersBasic))
  }

  getIndexOfAlreadyImported$() {
    return defer(() => http.get(`/api/messenger/v1/tenants/${this.tenantId}/indexAlreadyImported`, this.headersBasic))
  }

  importMessages$(payload: any): Observable<any> {
    return defer(() => http.post(`/api/messenger/v1/tenants/${this.tenantId}/importMessages`, payload, this.headersBasic)) as Observable<
      any
    >
  }

  hideMessages$(payload: any): Observable<any> {
    return defer(() => http.post(`/api/messenger/v1/tenants/${this.tenantId}/hideMessages`, payload, this.headersBasic)) as Observable<any>
  }

  create$(payload: any): Observable<any> {
    return defer(() => http.post(`/api/messenger/v1/tenants/${this.tenantId}/`, payload, this.headersBasic)) as Observable<any>
  }

  read$(id: string): Observable<any> {
    return defer(() => http.get(`/api/messenger/v1/tenants/${this.tenantId}/${id}`, this.headersBasic)) as Observable<any>
  }

  update$(id: string, payload: any): Observable<any> {
    return defer(() => http.post(`/api/messenger/v1/tenants/${this.tenantId}/${id}`, payload, this.headersBasic)) as Observable<any>
  }

  delete$(id: string): Observable<any> {
    return defer(() => http.delete(`/api/messenger/v1/tenants/${this.tenantId}/${id}`, this.headersBasic)) as Observable<any>
  }
}

export default MessengerSingletonService
