
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { Observable } from 'rxjs'
import GkDatePicker from '@/components/DatePicker.vue'
import _ from 'lodash'
import moment from 'moment'
import { map } from 'rxjs/operators'

interface TableItem {
  dayDate: string
  branch: string
  applicationsCount: number
  zaDvCount: number
  testDoneCount: number
  testPassedCount: number
  onlineInterviewsCount: number
  hiredThisWeekOfYearCount: number
}

@Component({ components: { GkDatePicker } })
export default class MonthlyFeedback extends Vue {
  private root!: VueGevekom
  private openMenu = false

  private branchesList: any[] = []
  private list: any[] = []
  private statDate = moment().startOf('month')
  private statPickerDate = moment().startOf('month').format('YYYY-MM-DD')
  private weekRange = ['2021-01-18', '2021-01-24']

  @Watch('statPickerDate')
  watchStatDate(newValue: any, oldValue: any) {
    // console.log('[MonthlyFeedback] Watched statPickerDate NEW:', newValue)
    // console.log('[MonthlyFeedback] Watched statPickerDate OLD:', oldValue)
    if (newValue !== oldValue) {
      this.statDate = moment(newValue).startOf('day')
      // this.createItemsList()
    }
  }

  private tableItems: TableItem[] = []
  private tableSumRow: TableItem | null = null
  private tableHeaders = [
    {
      text: 'Monat',
      align: 'start',
      value: 'dayDate',
      sortable: false
    },
    { text: 'Standort', value: 'branch' },
    { text: 'Bewerbungen', value: 'applicationsCount', align: 'center' },
    { text: 'ZA/DV', value: 'zaDvCount', align: 'center' },
    { text: 'Test erledigt', value: 'testDoneCount', align: 'center' },
    { text: 'Test bestanden', value: 'testPassedCount', align: 'center' },
    { text: 'Onlineinterviews', value: 'onlineInterviewsCount', align: 'center' },
    { text: 'Eingestellt in diesem Monat', value: 'hiredThisWeekOfYearCount', align: 'center' }
  ]

  private search = ''

  @Prop({ default: null }) readonly applicationsList!: Observable<any[]>
  @Prop({ default: null }) readonly branches!: Observable<any[]>

  get statisticsDate() {
    return this.statDate.format('DD.MM.YYYY')
  }

  get countApplicationsForStatDate() {
    const l = _.filter(this.list, (a: any) => {
      if (!a.applicationReceipt) {
        a.applicationReceipt = a.createdDate
      }
      return a.applicationReceipt.substr(0, 10) === this.statDate.format('YYYY-MM-DD')
    })
    return l.length
  }

  countApplicationsForStatDateAndBranch(branch: string) {
    const l = _.filter(this.list, (a: any) => {
      if (!a.applicationReceipt) {
        a.applicationReceipt = a.createdDate
      }
      return a.branch === branch && a.applicationReceipt.substr(0, 10) === this.statDate.format('YYYY-MM-DD')
    })
    return l.length
  }

  created() {
    console.log('[MonthlyFeedback] IS CREATED')
    this.root = this.$root as VueGevekom
    this.branches.subscribe((branches) => {
      this.branchesList = _.orderBy(branches, ['displayText'])
      this.applicationsList.subscribe((list) => {
        console.log('[MonthlyFeedback] received applications list:', list)
        this.list = [...list]
        this.createItemsList()
      })
    })
  }

  private loadApplicationsForSelectedMonth() {
    this.$store.commit('showProgressBar')
    const startDay = this.statDate.clone().startOf('month').format('YYYY-MM')

    this.root.applicationService
      .getMonthlyStatsIndex$(startDay)
      .pipe(
        map((response: any) => {
          return response.data.applications
        })
      )
      .subscribe(
        (response: any[]) => {
          console.log('[MonthlyFeedback] received applications list:', response)
          this.list = response
          this.createItemsList()
          this.$store.commit('hideProgressBar')
        },
        (error: any) => {
          console.error('[MonthlyFeedback] error:', error)
          this.$store.commit('hideProgressBar')
        }
      )
  }

  loadMonthSelection() {
    console.log('[MonthlyFeedback] Load month selection')
    this.$store.commit('showProgressBar')
    this.tableItems = []
    this.tableSumRow = {
      dayDate: '',
      branch: 'Summen:',
      applicationsCount: 0,
      zaDvCount: 0,
      testDoneCount: 0,
      testPassedCount: 0,
      onlineInterviewsCount: 0,
      hiredThisWeekOfYearCount: 0
    }
    this.loadApplicationsForSelectedMonth()
  }

  handleDateChange(event: any) {
    console.log('[MonthlyFeedback] handle change of statistics date:', event)
  }

  createItemsList() {
    console.log('[MonthlyFeedback] create new data table items list')
    const dayDate = this.statDate.format('YYYY-MM-DD')

    const newList: TableItem[] = []
    const newSumRow = {
      dayDate,
      branch: 'Summen:',
      applicationsCount: 0,
      zaDvCount: 0,
      testDoneCount: 0,
      testPassedCount: 0,
      onlineInterviewsCount: 0,
      hiredThisWeekOfYearCount: 0
    }

    const lApplications = this.list
    // const lApplications = _.filter(this.list, (a) => {
    //   moment.locale('de', { week: { dow: 1 } })
    //   const date = moment(a.applicationReceipt.substr(0, 10))
    //   return date.isSameOrAfter(this.statDate.startOf('month')) && date.isSameOrBefore(this.statDate.endOf('month'))
    // })
    // console.log('[MonthlyFeedback] start of week:', this.statDate.startOf('month').format('YYYY-MM-DD'))
    // console.log('[MonthlyFeedback] end of week:', this.statDate.endOf('month').format('YYYY-MM-DD'))
    const lTests = _.filter(this.list, (a: any) => {
      if (a.feedbackTestPassedAt) {
        moment.locale('de', { week: { dow: 1 } })
        const date = moment(a.feedbackTestPassedAt.substr(0, 10))
        return date.isSameOrAfter(this.statDate.startOf('month')) && date.isSameOrBefore(this.statDate.endOf('month'))
      }
      return false
    })
    const lInterviews = _.filter(this.list, (a: any) => {
      if (a.invitedToJobInterviewAt) {
        moment.locale('de', { week: { dow: 1 } })
        const date = moment(a.invitedToJobInterviewAt.substr(0, 10))
        return date.isSameOrAfter(this.statDate.startOf('month')) && date.isSameOrBefore(this.statDate.endOf('month'))
      }
      return false
    })
    const lHired = _.filter(this.list, (a: any) => {
      if (a.hiredDecisionMadeAt) {
        moment.locale('de', { week: { dow: 1 } })
        const date = moment(a.hiredDecisionMadeAt.substr(0, 10))
        return date.isSameOrAfter(this.statDate.startOf('month')) && date.isSameOrBefore(this.statDate.endOf('month'))
      }
      return false
    })

    for (const b of this.branchesList) {
      const newRow = {
        dayDate,
        branch: b.displayText,
        applicationsCount: 0,
        zaDvCount: 0,
        testDoneCount: 0,
        testPassedCount: 0,
        onlineInterviewsCount: 0,
        hiredThisWeekOfYearCount: 0
      }
      for (const a of lApplications) {
        if (a.branch === b.value) {
          newRow.applicationsCount += 1
          if (a.temporaryWorker || a.directBrokerWorker) {
            newRow.zaDvCount += 1
          }
        }
      }
      for (const a of lTests) {
        if (a.branch === b.value) {
          if (a.feedbackTest !== 'offen') {
            newRow.testDoneCount += 1
          }
          if (a.feedbackTest === 'successful') {
            newRow.testPassedCount += 1
          }
        }
      }
      for (const a of lInterviews) {
        if (a.branch === b.value) {
          newRow.onlineInterviewsCount += 1
        }
      }
      for (const a of lHired) {
        if (a.branch === b.value) {
          newRow.hiredThisWeekOfYearCount += 1
        }
      }
      newList.push(newRow)
      newSumRow.applicationsCount += newRow.applicationsCount
      newSumRow.zaDvCount += newRow.zaDvCount
      newSumRow.testDoneCount += newRow.testDoneCount
      newSumRow.testPassedCount += newRow.testPassedCount
      newSumRow.onlineInterviewsCount += newRow.onlineInterviewsCount
      newSumRow.hiredThisWeekOfYearCount += newRow.hiredThisWeekOfYearCount
    }
    // newList.push(newSumRow)
    this.tableSumRow = newSumRow

    this.tableItems = newList
  }
}
