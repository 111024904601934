import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import axios from 'axios'

import Dashboard from '@/views/Dashboard.vue'
import Applications from '@/views/Applications.vue'
import FormApplications from '@/views/FormApplications.vue'
import Settings from '@/views/Settings.vue'
import Skeleton from '@/views/Skeleton.vue'
import Logout from '@/views/Logout.vue'
import Authorize from '@/views/Authorize.vue'
import Statistics from '@/views/Statistics.vue'
import Exports from '@/views/Exports.vue'
import MpSelect from '@/views/MpSelect.vue'
import MassMailing from '@/views/MassMailing.vue'
import JobAdverts from '@/views/JobAdverts.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    component: Dashboard,
    meta: { requiresLogin: true }
  },
  {
    path: '/applications',
    component: Applications,
    meta: { requiresLogin: true }
  },
  {
    path: '/form-applications',
    component: FormApplications,
    meta: { requiresLogin: true }
  },
  {
    path: '/job-ad',
    component: JobAdverts,
    meta: { requiresLogin: true }
  },
  { path: '/mp-select', component: MpSelect, meta: { requiresLogin: true } },
  { path: '/settings', component: Settings, meta: { requiresLogin: true } },
  { path: '/statistics', component: Statistics, meta: { requiresLogin: true } },
  { path: '/massmailing', component: MassMailing, meta: { requiresLogin: true } },
  { path: '/exports', component: Exports, meta: { requiresLogin: true } },
  { path: '/skeleton', component: Skeleton, meta: { requiresLogin: true } },
  { path: '/logout', component: Logout, meta: { requiresLogin: true } },
  { path: '/authorize', component: Authorize },
  { path: '/web/index.html', redirect: '/dashboard' },
  { path: '/', redirect: '/dashboard' }
  // { path: '/web/index.html', redirect: '/applications' },
  // { path: '/', redirect: '/applications' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to: any, from, next) => {
  // console.log('[Router] before route enter to %o from %o with next %o', to, from, next)
  store.commit('showProgressBar')

  console.log('[Router] is Authenticated:', store.state.auth.isAuthenticated)

  if (to.query.code) {
    // console.log('[Router] Found code token:', to.query.code)
    // console.log('[Router] for accessing:', to.path)
    axios.get('/api/auth/v1/tenants/current').then((currentResponse) => {
      const params = new URLSearchParams()
      params.append('code', to.query.code)
      axios
        .post(`/api/auth/v1/tenants/${currentResponse?.data?.tenantId ? currentResponse.data.tenantId : '79d15315'}/authorize`, params)
        .then((response) => {
          console.log('[Application] posting to authorize now!')
          if (response.data.user) {
            const user = response.data.user
            store.commit('setUser', user) // .setUser(this.user);
            sessionStorage.setItem('user', JSON.stringify(user))
            // router.replace('/applications')
            router.replace('/dashboard')
          } else {
            router.replace('/')
          }
        })
    })
  }

  if (to.matched.some((record: { meta: { requiresLogin: boolean } }) => record.meta.requiresLogin)) {
    const sessionUser = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') || '') : null
    if (!store.state.auth.isAuthenticated && !sessionUser) {
      console.error('[Router] Authentication required, but not given!')
      store.commit('setShowSidebar', false)
      // const origin = window.location.origin
      // axios.get(`https://${process.env.VUE_APP_BASE_URL}/api/auth/v1/tenants/current`).then(response => {
      axios.get(`${origin}/api/auth/v1/tenants/current`).then((response) => {
        console.log('[Router] Authentication received current login config:', response)
        const url = response.data.loginUrl
        const location = window.location
        const host = location.host
        const protocol = location.protocol
        const redirect = `${protocol}//${host}/index.html`
        const redirectUrl = `${url}${redirect}`
        console.log('[Router] redirect to login:', redirectUrl)
        window.location.href = redirectUrl
      })
    } else if (!store.state.auth.isAuthenticated && sessionUser) {
      console.error('[Router] Authentication required and user is in session storage!')
      store.commit('setShowSidebar', true)
      store.commit('setUser', sessionUser) // .setUser(this.user);
      // router.replace('/applications')
      router.replace('/dashboard')
    } else {
      store.commit('setShowSidebar', true)
      next()
    }
  } else {
    store.commit('setShowSidebar', true)
    next()
  }
})

router.afterEach(() => {
  store.commit('hideProgressBar')
})

export default router
