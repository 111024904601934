
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { VueGevekom } from './Form.interfaces'
import pdf from 'vue-pdf'

@Component({ components: { pdf } })
export default class ApplicationsImage extends Vue {
  private root!: VueGevekom

  @Prop() readonly originalData!: any

  @Emit('addedDocument')
  addedDocument(doc: any[]) {
    console.log('[ApplicationsImage] added a new Document to application:', doc)
    return doc
  }

  get formattedZoom() {
    return '100'
  }

  private documents: any[] = []
  private confirmDeleteDialog = false
  private deleteDoc: any

  private filesForUpload: File | null = null
  private missingTitle = false
  private showDocument = null
  private docImageDialog = false
  private docPdfDialog = false
  private pdfdata: any = null
  private pageCount = 0
  private currentPage = 1
  private errors = []
  private scale = 100

  private documentsList = null

  created() {
    this.root = this.$root as VueGevekom
    console.log('[ApplicationsImage] created')
    console.log('[ApplicationsImage] originalData:', this.originalData)
    this.documents = this.originalData.documents
  }

  changedUploadFileSelection(file: File) {
    console.log('[ApplicationsImage] changed selection of upload files:', file)
    if (file) {
      this.filesForUpload = file
    } else {
      this.filesForUpload = null
    }
  }

  fileUploadClicked() {
    console.log('[ApplicationsImage] File upload clicked. Upload now these files:', this.filesForUpload)

    if (this.filesForUpload) {
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          const dataUrl: string = reader.result as string
          // console.log('[ApplicationsImage] loaded file:', dataUrl)
          this.root.applicationService
            .uploadApplicationAvatar$(this.originalData.id, 'Avatar', dataUrl, this.filesForUpload?.size || 0)
            .subscribe((response) => {
              console.log('[ApplicationsImage] Response from file upload:', response)
              this.filesForUpload = null
              if (response?.data?.avatar) {
                this.documents = response.data.avatar
                this.addedDocument(this.documents)
              }
            })
        },
        false
      )

      reader.readAsDataURL(this.filesForUpload)
    }
  }

  showDoc(doc: any) {
    console.log('[ApplicationsImage] Show this doc in dialog:', doc)
    this.showDocument = doc
    if (doc.type === 'pdf') {
      this.docPdfDialog = true
    } else {
      this.docImageDialog = true
    }
  }

  getDocumentUrl(doc: any): string {
    console.log('[ApplicationsImage] asked for document url for:', doc)
    if (!doc) {
      return ''
    }
    // console.log('[ApplicationsImage] process.env:', process.env)
    // console.log('[ApplicationsImage] process.env.VUE_APP_BASE_URL:', process.env.VUE_APP_BASE_URL)
    if (process.env.NODE_ENV === 'development') {
      return `https://${process.env.VUE_APP_BASE_URL}/!${this.root.applicationService.token}/api/applications/v1/tenants/${this.root.applicationService.tenantId}/${this.originalData.id}/avatar/${doc.id}`
    }
    return `/!${this.root.applicationService.token}/api/applications/v1/tenants/${this.root.applicationService.tenantId}/${this.originalData.id}/avatar/${doc.id}`
  }

  deleteDocument(doc: any) {
    console.log('[ApplicationsImage] Delete this document:', doc)
    this.deleteDoc = doc
    this.confirmDeleteDialog = true
  }

  deleteThisDocument() {
    console.log('[ApplicationsImage] Delete this document:', this.deleteDoc)
    this.confirmDeleteDialog = false
    this.root.applicationService.deleteApplicationDocument$(this.originalData.id, this.deleteDoc.id).subscribe((response) => {
      console.log('[ApplicationsImage] Response from document delete:', response)
      if (response?.data?.documents) {
        this.documents = response.data.documents
        this.addedDocument(this.documents)
      }
    })
  }

  nextPage() {
    if (this.currentPage < this.pageCount) {
      this.currentPage++
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--
    }
  }
}
