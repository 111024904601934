
import { Vue, Component, Watch } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import GkDatePicker from '@/components/DatePicker.vue'
// import _ from 'lodash'
import moment from 'moment'
import { map } from 'rxjs/operators'

@Component({ components: { GkDatePicker } })
export default class RecruiterStatistics extends Vue {
  private root!: VueGevekom

  private dateRange = [moment().startOf('day').subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')]
  private disableLoadButton = false

  private tableItems: any[] = []
  private tableSumRow: any = { branch: 'Summen:', applications: 0 }
  private tablePercentRow: any = { branch: 'in %:', applications: '' }

  tableHeaderDefault = [
    { text: 'Zeitraum', value: 'range', sortable: false, align: 'start', width: '250px' },
    { text: 'Recruiter', value: 'recruiter', sortable: false, align: 'start', width: '250px' },
    { text: 'Im Zeitraum eingestellt', value: 'hired', sortable: false, align: 'start', width: '100%' }
  ]

  private tableHeaders = this.tableHeaderDefault

  @Watch('dateRange')
  watchDateRange(newValue: any, oldValue: any) {
    console.log('[RecruiterStatistics] Watched dateRange NEW:', newValue)
    console.log('[RecruiterStatistics] Watched dateRange OLD:', oldValue)
    if (newValue && newValue.length === 2) {
      console.log('[RecruiterStatistics] Jetzt sollten neue Daten für die neue Range abgeholt und angezeigt werden!')
      const range = [...newValue]
      range.sort()
      // this.dateRange.sort()
      this.disableLoadButton = false
    } else {
      this.tableHeaders = this.tableHeaderDefault
      this.tableSumRow = { branch: 'Summen:', applications: 0 }
      this.tablePercentRow = { branch: 'in %:', applications: '' }
      this.tableItems = []
      this.disableLoadButton = true
    }
  }

  get dateRangeText(): string {
    return `Zeitraum gewählt vom ${moment(new Date(this.dateRange[0])).format('DD.MM.YYYY')} ${this.dateRange[1] ? ` bis ${moment(new Date(this.dateRange[1])).format('DD.MM.YYYY')}` : ''
      }`
  }

  created() {
    console.log('[RecruiterStatistics] IS CREATED')
    this.root = this.$root as VueGevekom
    // this.$store.commit('showProgressBar')

    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.applicationService.initService(tenantId, token)
    }
  }

  private loadApplicationsForRange(rangeStart: string, rangeEnd: string) {
    this.$store.commit('showProgressBar')
    this.root.applicationService
      .getRecruiterStats$(rangeStart, rangeEnd)
      .pipe(
        map((response: any) => {
          return response.data.statistics
        })
      )
      .subscribe((statsResponse: any) => {
        console.log('[RecruiterStatistics] Received new data collection for stats:', statsResponse)
        this.reCalcTableItems(statsResponse, rangeStart, rangeEnd)
      })
  }

  loadRangeSelection() {
    console.log('[RecruiterStatistics] Load range selection')
    this.loadApplicationsForRange(this.dateRange[0], this.dateRange[1])
  }

  dateRangeChanged(evt: any) {
    console.log('[RecruiterStatistics] Date range changed:', evt)
    this.dateRange.sort()
  }

  private reCalcTableItems(statsResponse: any, rangeStart: string, rangeEnd: string) {
    console.log('[RecruiterStatistics] received application channel stats:', statsResponse)
    const RecruiterStatisticsConfig = this.$store.state.company.configs.recruiter
    const recruiterMap: { [value: string]: string } = {}
    for (const recruiter of RecruiterStatisticsConfig) {
      recruiterMap[recruiter.value] = recruiter.displayText
    }
    console.log('[RecruiterStatistics] RecruiterStatisticsConfig:', RecruiterStatisticsConfig)
    this.tableHeaders = this.tableHeaderDefault

    this.tableItems = []
    for (const recruiter of Object.keys(statsResponse)) {
      if (recruiter === 'total') {
        // this.tableSumRow = { range: `${moment(new Date(rangeStart)).format('DD.MM.YYYY')} - ${moment(new Date(rangeEnd)).format('DD.MM.YYYY')}`, recruiter: 'Summe:', applications: statsResponse[recruiter].applicationsCount, hired: statsResponse[recruiter].hiredCount }
        this.tableSumRow = { range: `${moment(new Date(rangeStart)).format('DD.MM.YYYY')} - ${moment(new Date(rangeEnd)).format('DD.MM.YYYY')}`, recruiter: 'Summe:', hired: `${statsResponse[recruiter].hiredCount}` }
        continue
      }
      const newRow = { range: `${moment(new Date(rangeStart)).format('DD.MM.YYYY')} - ${moment(new Date(rangeEnd)).format('DD.MM.YYYY')}`, recruiter: recruiterMap[recruiter], hired: statsResponse[recruiter].hiredCount }
      this.tableItems.push(newRow)
    }

    this.$store.commit('hideProgressBar')
  }
}
