import { BehaviorSubject, defer, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import http from '../http-common'
import { GetEmailTemplateTypesResponse, GetEmailTemplatesResponse } from '../appInterfaces/emailTemplates.interfaces'

class EmailTemplatesSingletonService {
  private emailTemplatesIndex = new BehaviorSubject<any>([])

  private _isInited = false
  private headersBasic: Record<string, any> = {}

  public tenantId!: string
  public token!: string

  get isInited(): boolean {
    return this._isInited
  }

  get emailTemplatesIndex$(): Observable<any> {
    return this.emailTemplatesIndex.asObservable()
  }

  get emailTemplatePlaceholders() {
    return [
      { displayText: 'Geschlecht', value: 'sex' },
      { displayText: 'Vorname', value: 'firstName' },
      { displayText: 'Nachname', value: 'lastName' },
      { displayText: 'Standort', value: 'branch' },
      { displayText: 'Einstellungsdatum', value: 'hiringDate' },
      { displayText: 'Email Signatur Name', value: 'emailSenderName' },
      { displayText: 'Email Signatur Position', value: 'emailSenderPosition' },
      { displayText: 'Email Signatur Absender-Email', value: 'emailSenderReturnEmail' }
    ]
  }

  initService(tenantId: string, token: string, overwrite = false) {
    if (!this._isInited || overwrite) {
      this.tenantId = tenantId
      this.token = token
      this.headersBasic = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
      this._isInited = true
    }
  }

  getTest$(): Observable<any> {
    return defer(() => http.get(`/api/email-templates/v1/tenants/${this.tenantId}/test`, this.headersBasic))
  }

  getEmailTemplateTypes$(): Observable<GetEmailTemplateTypesResponse> {
    return defer(() => http.get(`/api/email-templates/v1/tenants/${this.tenantId}/emailTemplateTypes`, this.headersBasic)) as Observable<GetEmailTemplateTypesResponse>
  }

  getIndex$(): Observable<GetEmailTemplatesResponse> {
    return defer(() => http.get(`/api/email-templates/v1/tenants/${this.tenantId}/`, this.headersBasic)).pipe(
      map((idx: any) => {
        console.log('[EmailTemplatesSingletonService] Map Email Templates Index:', idx)
        if (idx.data?.emailTemplates?.length >= 0) {
          this.emailTemplatesIndex.next(idx.data.emailTemplates)
        }
        return idx
      })
    ) as Observable<GetEmailTemplatesResponse>
  }

  create$(payload: any): Observable<any> {
    return defer(() => http.post(`/api/email-templates/v1/tenants/${this.tenantId}/`, payload, this.headersBasic)) as Observable<any>
  }

  read$(id: string): Observable<any> {
    return defer(() => http.get(`/api/email-templates/v1/tenants/${this.tenantId}/${id}`, this.headersBasic)) as Observable<any>
  }

  update$(id: string, payload: any): Observable<any> {
    return defer(() => http.post(`/api/email-templates/v1/tenants/${this.tenantId}/${id}`, payload, this.headersBasic)) as Observable<any>
  }

  delete$(id: string): Observable<any> {
    return defer(() => http.delete(`/api/email-templates/v1/tenants/${this.tenantId}/${id}`, this.headersBasic)) as Observable<any>
  }

  uploadImage$(payload: any): Observable<any> {
    return defer(() => http.post(`/api/email-templates/v1/tenants/${this.tenantId}/images/upload`, payload, this.headersBasic)) as Observable<any>
  }
}

export default EmailTemplatesSingletonService
