
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { forkJoin, Observable } from 'rxjs'
import GkDatePicker from '@/components/DatePicker.vue'
import _ from 'lodash'
import moment from 'moment'
import { map } from 'rxjs/operators'

interface TableItem {
  dayDate: string
  branch: string
  applicationsCount: number
  zaDvCount: number
  testDoneCount: number
  testPassedCount: number
  onlineInterviewsCount: number
  hiredThisWeekOfYearCount: number
}

@Component({ components: { GkDatePicker } })
export default class WeeklyFeedback extends Vue {
  private root!: VueGevekom
  private openMenu = false

  private branchesList: any[] = []
  private list: any[] = []
  private statDate = moment().startOf('day')
  private statPickerDate = moment().startOf('day').format('YYYY-MM-DD')

  @Watch('statPickerDate')
  watchStatDate(newValue: any, oldValue: any) {
    // console.log('[WeeklyFeedback] Watched statPickerDate NEW:', newValue)
    // console.log('[WeeklyFeedback] Watched statPickerDate OLD:', oldValue)
    if (newValue !== oldValue) {
      const newDate = moment(new Date(newValue))
      // console.log('[WeeklyFeedback] Watched statPickerDate NEW DATE:', newDate.format('YYYY-MM-DD'))
      this.statDate = newDate.clone()
      // console.log('[WeeklyFeedback] Watched statPickerDate:', this.statDate.format('YYYY-MM-DD'))
      // this.createItemsList()
    }
  }

  private tableItems: TableItem[] = []
  private tableSumRow: TableItem | null = null
  private tableHeaders = [
    {
      text: 'Woche',
      align: 'start',
      value: 'dayDate',
      sortable: false
    },
    { text: 'Standort', value: 'branch' },
    { text: 'Bewerbungen', value: 'applicationsCount', align: 'center' },
    { text: 'ZA/DV', value: 'zaDvCount', align: 'center' },
    { text: 'Test erledigt', value: 'testDoneCount', align: 'center' },
    { text: 'Test bestanden', value: 'testPassedCount', align: 'center' },
    { text: 'Onlineinterviews', value: 'onlineInterviewsCount', align: 'center' },
    { text: 'Eingestellt in dieser KW', value: 'hiredThisWeekOfYearCount', align: 'center' }
  ]

  private search = ''

  @Prop({ default: null }) readonly applicationsList!: Observable<any[]>
  @Prop({ default: null }) readonly branches!: Observable<any[]>

  get statisticsDate() {
    return this.statDate.format('DD.MM.YYYY')
  }

  get countApplicationsForStatDate() {
    const l = _.filter(this.list, (a: any) => {
      if (!a.applicationReceipt) {
        a.applicationReceipt = a.createdDate
      }
      return a.applicationReceipt.substr(0, 10) === this.statDate.format('YYYY-MM-DD')
    })
    return l.length
  }

  countApplicationsForStatDateAndBranch(branch: string) {
    const l = _.filter(this.list, (a: any) => {
      if (!a.applicationReceipt) {
        a.applicationReceipt = a.createdDate
      }
      return a.branch === branch && a.applicationReceipt.substr(0, 10) === this.statDate.format('YYYY-MM-DD')
    })
    return l.length
  }

  created() {
    console.log('[WeeklyFeedback] IS CREATED')
    this.root = this.$root as VueGevekom
    this.branches.subscribe((branches) => {
      this.branchesList = _.orderBy(branches, ['displayText'])
      this.applicationsList.subscribe((list) => {
        console.log('[WeeklyFeedback] received applications list:', list)
        this.list = [...list]
        this.createItemsList()
      })
    })
  }

  private loadApplicationsForSelectedWeek() {
    this.$store.commit('showProgressBar')
    const forkJoins: Observable<any>[] = []

    const startDay = this.statDate.clone().startOf('isoWeek')

    forkJoins.push(
      this.root.applicationService.getWeeklyStatsIndex$(startDay.format('YYYY-MM-DD')).pipe(
        map((response: any) => {
          return response.data.applications
        })
      )
    )

    forkJoin(forkJoins).subscribe(
      (response: any[]) => {
        console.log('[WeeklyFeedback] received applications list:', response)
        this.list = [...response[0]]
        this.createItemsList()
        this.$store.commit('hideProgressBar')
      },
      (error: any) => {
        console.error('[WeeklyFeedback] error:', error)
        this.$store.commit('hideProgressBar')
      }
    )
  }

  loadWeekSelection() {
    console.log('[WeeklyFeedback] Load day selection')
    this.$store.commit('showProgressBar')
    this.tableItems = []
    this.tableSumRow = {
      dayDate: '',
      branch: 'Summen:',
      applicationsCount: 0,
      zaDvCount: 0,
      testDoneCount: 0,
      testPassedCount: 0,
      onlineInterviewsCount: 0,
      hiredThisWeekOfYearCount: 0
    }
    this.loadApplicationsForSelectedWeek()
  }

  handleDateChange(event: any) {
    console.log('[WeeklyFeedback] handle change of statistics date:', event)
  }

  createItemsList() {
    console.log('[WeeklyFeedback] create new data table items list:', this.list)
    const dayDate = this.statDate.format('YYYY-MM-DD')

    const newList: TableItem[] = []
    const newSumRow = {
      dayDate,
      branch: 'Summen:',
      applicationsCount: 0,
      zaDvCount: 0,
      testDoneCount: 0,
      testPassedCount: 0,
      onlineInterviewsCount: 0,
      hiredThisWeekOfYearCount: 0
    }

    const lApplications = _.filter(this.list, (a: any) => {
      moment.locale('de', { week: { dow: 1 } })
      if (!a || !a.applicationReceipt) {
        // console.log('[WeeklyFeedback] createItemsList: applicationReceipt is null for:', a)
        a.applicationReceipt = a.createdDate
        // return false
      }
      const date = moment(a.applicationReceipt.substr(0, 10))
      return date.isSameOrAfter(this.statDate.startOf('isoWeek')) && date.isSameOrBefore(this.statDate.endOf('isoWeek'))
    })
    // console.log('[WeeklyFeedback] statDate:', this.statDate.format('YYYY-MM-DD'))
    // console.log('[WeeklyFeedback] start of week:', this.statDate.startOf('isoWeek').format('YYYY-MM-DD'))
    // console.log('[WeeklyFeedback] end of week:', this.statDate.endOf('isoWeek').format('YYYY-MM-DD'))
    // console.log('[WeeklyFeedback] filtered applications:', lApplications)

    const lTests = _.filter(this.list, (a: any) => {
      if (a.feedbackTestPassedAt) {
        moment.locale('de', { week: { dow: 1 } })
        const date = moment(a.feedbackTestPassedAt.substr(0, 10))
        return date.isSameOrAfter(this.statDate.startOf('isoWeek')) && date.isSameOrBefore(this.statDate.endOf('isoWeek'))
      }
      return false
    })
    const lInterviews = _.filter(this.list, (a: any) => {
      if (a.invitedToJobInterviewAt) {
        moment.locale('de', { week: { dow: 1 } })
        const date = moment(a.invitedToJobInterviewAt.substr(0, 10))
        return date.isSameOrAfter(this.statDate.startOf('isoWeek')) && date.isSameOrBefore(this.statDate.endOf('isoWeek'))
      }
      return false
    })
    const lHired = _.filter(this.list, (a: any) => {
      if (a.hiredDecisionMadeAt) {
        moment.locale('de', { week: { dow: 1 } })
        const date = moment(a.hiredDecisionMadeAt.substr(0, 10))
        return date.isSameOrAfter(this.statDate.startOf('isoWeek')) && date.isSameOrBefore(this.statDate.endOf('isoWeek'))
      }
      return false
    })

    for (const b of this.branchesList) {
      const newRow = {
        dayDate,
        branch: b.displayText,
        applicationsCount: 0,
        zaDvCount: 0,
        testDoneCount: 0,
        testPassedCount: 0,
        onlineInterviewsCount: 0,
        hiredThisWeekOfYearCount: 0
      }
      for (const a of lApplications) {
        // console.log('[WeeklyFeedback] application:', a)
        // console.log('[WeeklyFeedback] branch:', b.displayText)
        if (a.branch === b.value) {
          // console.log('[WeeklyFeedback] found application for branch:', a.branch)
          newRow.applicationsCount += 1
          if (a.temporaryWorker || a.directBrokerWorker) {
            newRow.zaDvCount += 1
          }
        }
      }
      for (const a of lTests) {
        if (a.branch === b.value) {
          if (a.feedbackTest !== 'offen') {
            newRow.testDoneCount += 1
          }
          if (a.feedbackTest === 'successful') {
            newRow.testPassedCount += 1
          }
        }
      }
      for (const a of lInterviews) {
        if (a.branch === b.value) {
          newRow.onlineInterviewsCount += 1
        }
      }
      for (const a of lHired) {
        if (a.branch === b.value) {
          newRow.hiredThisWeekOfYearCount += 1
        }
      }
      newList.push(newRow)
      newSumRow.applicationsCount += newRow.applicationsCount
      newSumRow.zaDvCount += newRow.zaDvCount
      newSumRow.testDoneCount += newRow.testDoneCount
      newSumRow.testPassedCount += newRow.testPassedCount
      newSumRow.onlineInterviewsCount += newRow.onlineInterviewsCount
      newSumRow.hiredThisWeekOfYearCount += newRow.hiredThisWeekOfYearCount
    }
    // newList.push(newSumRow)
    this.tableSumRow = newSumRow

    this.tableItems = newList
  }
}
