
import { Component, Vue } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { Tile } from '@/appServices/rights.singleton.service'
import DashboardCommentApplicationsComponent from '@/components/dashboard/DashboardCommentApplication.vue'
import ApplicationsCommentForm from '@/components/applications/CommentsForm.vue'

@Component({ components: { DashboardCommentApplicationsComponent, ApplicationsCommentForm } })
export default class DashboardComponent extends Vue {
  private root!: VueGevekom

  private cards: Tile[] = []
  private tileCols = 3
  private showCommentForm = false
  private commentApplication: any

  created() {
    console.log('[DashboardComponent] Created the DashboardComponent class')
    this.root = this.$root as VueGevekom
    if (!this.root.applicationService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.applicationService.initService(tenantId, token)
    }
    this.root.rightsService.dashboardTiles.subscribe((tiles) => {
      this.cards = []
      for (const tile of tiles) {
        if (this.root.rightsService.hasAuthedUserThisRight(tile.right || '')) {
          this.cards.push(tile)
        }
      }
    })
    this.root.applicationService.getDashboardTiles$().subscribe((tiles: Tile[]) => {
      this.root.rightsService.dashboardTiles.next(tiles)
    })
  }

  onResize() {
    console.log('Window width: ' + window.innerWidth)
    if (window.innerWidth < 700) {
      console.log('XTRA-SMALL')
      this.tileCols = 12
    } else if (window.innerWidth >= 700 && window.innerWidth < 960) {
      console.log('SMALL')
      this.tileCols = 6
    } else if (window.innerWidth >= 960 && window.innerWidth < 1264) {
      console.log('MIDDLE')
      this.tileCols = 6
    } else if (window.innerWidth >= 1264 && window.innerWidth < 1904) {
      console.log('LARGE')
      this.tileCols = 4
    } else {
      console.log('XTRA-LARGE')
      this.tileCols = 3
    }
  }

  commentItem(application: any) {
    this.showCommentForm = true
    this.commentApplication = application
    console.log('[DashboardComponent] Comment this application:', application)
  }
}
