
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import _ from 'lodash'
import ApplicationsSingletonService, { ApplicationGetIndexOptions } from '@/appServices/applications.singleton.service'

@Component
export default class FilterRow extends Vue {
  @Prop({ default: null }) readonly applicationService!: ApplicationsSingletonService
  @Prop({ default: [] }) readonly exportFields!: string[]

  private loadingApplications = false

  private originalData!: any[]

  private filterTypes = ['Monat', 'Quartal', 'Jahr']
  private filterType: string | null = null
  private filterYears = ['2021']
  private filterYear: string | null = null
  private filterMonths = [
    { displayText: 'Januar', value: '01' },
    { displayText: 'Februar', value: '02' },
    { displayText: 'März', value: '03' },
    { displayText: 'April', value: '04' },
    { displayText: 'Mai', value: '05' },
    { displayText: 'Juni', value: '06' },
    { displayText: 'Juli', value: '07' },
    { displayText: 'August', value: '08' },
    { displayText: 'September', value: '09' },
    { displayText: 'Oktober', value: '10' },
    { displayText: 'November', value: '11' },
    { displayText: 'Dezember', value: '12' }
  ]

  private filterMonth: string | null = null

  private filterQuartals = [
    { displayText: 'Januar-März', value: '1', startMonth: '01', endMonth: '03' },
    { displayText: 'April-Juni', value: '2', startMonth: '04', endMonth: '06' },
    { displayText: 'Juli-September', value: '3', startMonth: '07', endMonth: '09' },
    { displayText: 'Oktober-Dezember', value: '4', startMonth: '10', endMonth: '12' }
  ]

  private filterQuartal: string | null = null

  private applicationTypes = ['Alle', 'Agent', 'FK']
  private applicationType: string | null = 'Alle'

  private branches = [{ displayText: 'Alle', value: 'all' }]
  private branch: string[] | null = ['Alle']

  private statusTypes = [{ displayText: 'Alle', value: 'all' }]
  private statusType: string[] | null = ['Alle']

  @Watch('branch')
  onBranchChanged(newValue: string[], oldValue: string[]) {
    console.log('[FilterRow] branch selection changed:', newValue)
    console.log('[FilterRow] branch selection changed oldValue:', oldValue)
    if (oldValue.includes('Alle') && newValue?.length > 1) {
      _.remove(this.branch || [], (item: string) => {
        return item === 'Alle'
      })
    }
    if (!oldValue.includes('Alle') && newValue.includes('Alle')) {
      this.branch = ['Alle']
    }
    if (this.branch?.length === 0) {
      this.branch = ['Alle']
    }
    console.log('[FilterRow] branch selection changed v-model:', this.branch)
  }

  @Watch('statusType')
  onStatusTypeChanged(newValue: string[], oldValue: string[]) {
    console.log('[FilterRow] statusType selection changed:', newValue)
    console.log('[FilterRow] statusType selection changed oldValue:', oldValue)
    if (oldValue.includes('Alle') && newValue?.length > 1) {
      _.remove(this.statusType || [], (item: string) => {
        return item === 'Alle'
      })
    }
    if (!oldValue.includes('Alle') && newValue.includes('Alle')) {
      this.statusType = ['Alle']
    }
    if (this.statusType?.length === 0) {
      this.statusType = ['Alle']
    }
    console.log('[FilterRow] branch selection changed v-model:', this.statusType)
  }

  @Emit('filteredData')
  filteredData(applications: any[]) {
    console.log('[ExportsComponent -> FilterRow] emit filtered data:', applications)
  }

  created() {
    this.statusTypes = [...this.statusTypes, ...this.$store.state.company.configs.status]
    this.branches = [...this.branches, ...this.$store.state.company.configs.branches]
    const actualYear = moment().year()
    this.filterYears = []
    for (let year = 2021; year <= actualYear; year++) {
      this.filterYears.push(year.toString())
    }
    this.filterType = 'Monat'
    this.filterYear = actualYear.toString()
    this.filterMonth = moment().format('MM')
  }

  loadApplicationsWithFilterParams() {
    this.$store.commit('showProgressBar')
    this.loadingApplications = true

    let quartalValue: any
    const options: ApplicationGetIndexOptions = {}
    switch (this.filterType) {
      case 'Monat':
        options.yearMonth = `${this.filterYear}-${this.filterMonth}`
        break
      case 'Quartal':
        console.log('[ExportsComponent -> FilterRow] this.filterQuartal:', this.filterQuartal)

        for (const filterQuartal of this.filterQuartals) {
          if (filterQuartal.value === this.filterQuartal) {
            quartalValue = { ...filterQuartal }
            break
          }
        }
        options.quartal = `${this.filterYear}-${quartalValue ? quartalValue.startMonth : '01'}`
        break
      case 'Jahr':
        options.year = `${this.filterYear}`
        break

      default:
        break
    }

    this.applicationService.getIndex$(options).subscribe(
      (response: any) => {
        console.log('[ExportsComponent -> FilterRow] Get applications index from Server:', response)
        this.$store.commit('setIncomingApplications', response.data.applications)
        this.createFilteredData([...response.data.applications])
        this.$store.commit('hideProgressBar')
        this.loadingApplications = false
      },
      (err: any) => {
        console.error('[ExportsComponent -> FilterRow] ERROR:', err)
        this.$store.commit('setIncomingApplications', [])
        switch (err.request.status) {
          case 403:
            this.$router.push('/logout')
            break

          default:
            break
        }
        this.$store.commit('hideProgressBar')
        this.loadingApplications = false
      }
    )
  }

  filterWithParams() {
    console.log('[ExportsComponent -> FilterRow] filter data.')
    // console.log('[ExportsComponent -> FilterRow] filter type:', this.filterType)
    // console.log('[ExportsComponent -> FilterRow] filter year:', this.filterYear)
    // console.log('[ExportsComponent -> FilterRow] filter month:', this.filterMonth)
    // console.log('[ExportsComponent -> FilterRow] filter quartal:', this.filterQuartal)
    let originalData = [...this.originalData]

    if (this.applicationType !== 'Alle') {
      originalData = _.filter(originalData, (item: any) => {
        if (item && this.applicationType === 'Agent') {
          return item.applicationAs === 'Customer Service Agent'
        } else if (item && this.applicationType === 'FK') {
          return item.applicationAs !== 'Customer Service Agent'
        }
        return false
      })
    }

    if (!this.branch?.includes('Alle')) {
      originalData = _.filter(originalData, (item: any) => {
        if (item) {
          return this.branch?.includes(item.branch)
        }
        return false
      })
    }

    if (!this.statusType?.includes('Alle')) {
      originalData = _.filter(originalData, (item: any) => {
        if (item) {
          return this.statusType?.includes(item.status)
        }
        return false
      })
    }

    this.filteredData(originalData)
  }

  private createFilteredData(fromApplications: any) {
    this.originalData = _.map(fromApplications, (appl: any) => {
      const d: any = {}

      for (const key of this.exportFields) {
        if (
          [
            'rejectedReason',
            'languageSkill',
            'writingSkill',
            'applicationAs',
            'feedbackTest',
            'project',
            'branch',
            'sex',
            'status',
            'recruiter',
            'skills'
          ].includes(key)
        ) {
          let displayText: any
          switch (key) {
            case 'sex':
              displayText = _.find(this.$store.state.company.configs.sex, { value: appl[key] })
              break
            case 'status':
              displayText = _.find(this.$store.state.company.configs.status, { value: appl[key] })
              break
            case 'applicationAs':
              displayText = _.find(this.$store.state.company.configs.jobs, { value: appl[key] })
              break
            case 'branch':
              displayText = _.find(this.$store.state.company.configs.branches, { value: appl[key] })
              break
            case 'applicationChannel':
              displayText = _.find(this.$store.state.company.configs.applicationChannels, { value: appl[key] })
              break
            case 'project':
              displayText = _.find(this.$store.state.company.configs.projects, { value: appl[key] })
              break
            case 'feedbackTest':
              displayText = _.find(this.$store.state.company.configs.feedbackTests, { value: appl[key] })
              break
            case 'languageSkill':
              displayText = _.find(this.$store.state.company.configs.languageSkills, { value: appl[key] })
              break
            case 'writingSkill':
              displayText = _.find(this.$store.state.company.configs.writingSkills, { value: appl[key] })
              break
            case 'rejectedReason':
              displayText = _.find(this.$store.state.company.configs.rejectReasons, { value: appl[key] })
              break
            case 'recruiter':
              displayText = _.find(this.$store.state.company.configs.recruiter, { value: appl[key] })
              break
            case 'skills':
              displayText = { displayText: '' }
              if (appl.skills?.length > 0) {
                appl.skills.forEach((skill: any) => {
                  if (skill.active && skill.checked) {
                    displayText = { displayText: `${displayText.displayText}${skill.displayName}, ` }
                  }
                })
                if (displayText.displayText.length > 0) {
                  displayText = { displayText: displayText.displayText.slice(0, -2) }
                }
              }
              break
            default:
              break
          }
          d[key] = displayText?.displayText || null
        } else {
          if (key === 'blacklistedEmail') {
            d[key] = appl[key] ? 'Gesperrt' : 'Nein'
          } else {
            d[key] = appl[key] || null
          }
        }
      }
      return d
    })
    console.log('[ExportsComponent -> FilterRow] filled array of applications:', this.originalData)
    this.filterWithParams()
  }
}
