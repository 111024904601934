
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { VueGevekom } from './Form.interfaces'

@Component
export default class ActionsRow extends Vue {
  @Prop({ default: false }) readonly showTableActionRow!: boolean
  @Prop({ default: false }) readonly showEditActionRow!: boolean
  @Prop({ default: false }) readonly showCommentActionRow!: boolean
  @Prop({ default: false }) readonly isFormValid!: boolean

  private root!: VueGevekom

  private sections = ['Agent', 'Führungskraft']
  private selectedSection = 'Agent'

  private timeRanges = [
    { displayText: 'Letzter Monat', value: 'last-month' },
    { displayText: 'Letzte 3 Monate', value: 'last-three-month' },
    { displayText: 'Letzte 6 Monate', value: 'last-six-month' },
    { displayText: 'Letzte 12 Monate', value: 'last-twelve-month' },
    { displayText: 'Alle', value: 'all' }
  ]

  private selectedTimeRange = 'last-month'

  @Watch('selectedSection')
  onSectionChanged() {
    this.sectionChanged()
  }

  @Watch('selectedTimeRange')
  onTimeRangeChanged() {
    this.timeRangeChangedChanged()
  }

  @Emit('sectionChanged')
  sectionChanged() {
    console.log('[Applications -> ActionsRow] Another section selected:', this.selectedSection)
    return this.selectedSection
  }

  @Emit('timeRangeChanged')
  timeRangeChangedChanged() {
    console.log('[Applications -> ActionsRow] Another time range selected:', this.selectedTimeRange)
    return this.selectedTimeRange
  }

  @Emit('addNewApplication')
  addNewApplication() {
    console.log('[Applications -> ActionsRow] Add a new Application to applications list.')
  }

  @Emit('reloadApplications')
  reloadApplications() {
    console.log('[Applications -> ActionsRow] reload Applications for applications list.')
  }

  @Emit('checkDoubleEmailApplications')
  checkDoubleEmailApplications() {
    console.log('[Applications -> ActionsRow] get checkDoubleEmailApplications for applications list.')
  }

  @Emit('saveForm')
  saveFormEdit() {
    console.log('[Applications -> ActionsRow] Save of editing applications form.')
  }

  @Emit('cancelForm')
  cancelFormEdit() {
    console.log('[Applications -> ActionsRow] Cancel of editing applications form.')
  }

  created() {
    this.root = this.$root as VueGevekom
  }
}
