
import { Vue, Component } from 'vue-property-decorator'
import ExportsHeader from '@/components/exports/Header.vue'
import ExportsComponent from '@/components/exports/Exports.vue'

@Component({
  components: {
    ExportsHeader,
    ExportsComponent
  }
})
export default class Exports extends Vue {}
