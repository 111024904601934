
import { Component, Vue, Emit } from 'vue-property-decorator'

@Component
export default class FormApplicationsHeader extends Vue {
  showCsvImportButton = true
  @Emit('startImportCsvProcess')
  startImportCsvProcess() {
    console.log('[FormApplicationsHeader] start import csv process')
    this.showCsvImportButton = !this.showCsvImportButton
  }
}
