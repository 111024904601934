
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
// import { Application } from '@/appInterfaces/applications.interfaces'
// import { ApplicationStatus } from '@/appEnums/applications.enums'
import moment from 'moment'
import GkDatePicker from '@/components/DatePicker.vue'
import GkTimePicker from '@/components/TimePicker.vue'
import ApplicationsDocuments from './ApplicationsDocuments.vue'
import { VueGevekom } from './Form.interfaces'
import _ from 'lodash'
import { ApplicationComment } from '@/appServices/applications.singleton.service'

@Component({ components: { GkDatePicker, GkTimePicker, ApplicationsDocuments } })
export default class ApplicationsCommentForm extends Vue {
  private root!: VueGevekom

  @Prop({ default: 'Neue' }) readonly kind!: string
  @Prop() readonly originalData!: any

  @Emit('isFormValid')
  isFormValid() {
    return (this.$refs.form as HTMLFormElement).validate()
  }

  @Emit('addedDocument')
  addedDocument(doc: any[]) {
    console.log('[ApplicationsCommentForm] added a new Document to application:', doc)
    return doc
  }

  private valid = true
  private nameRules = [(v: any) => !!v || 'Name is required', (v: any) => (v && v.length <= 10) || 'Name must be less than 10 characters']

  private emailRules = [(v: any) => !!v || 'E-mail is required', (v: any) => /.+@.+\..+/.test(v) || 'E-mail must be valid']

  private genders = ['männlich', 'weiblich', 'divers']
  private status = ['Einstellung', 'Absage', 'Zwischenbescheid']
  private statusValue = null

  private checkbox = false

  private dataSet: any

  private temporaryWorkerSelectedValue = false
  private directBrokerWorkerSelectedValue = false

  private birthdayMenu = false
  private birthdate = null

  private applicationReceiptMenu = false
  private applicationReceipt = null

  private acknowledgementOfApplicationSentAtMenu = false

  private invitedToJobInterviewAt = null
  private invitedToJobInterviewTime: string | null = null
  private invitedToJobInterviewAtMenu = null
  private invitedToJobInterviewAtMenu2 = false

  private feedbackJobInterviewAtMenu = false
  private showHiringDate = false
  private showRejectedReason = false
  private selectedDecision: string | null = null
  private selectedVideoAssessment: boolean | null = null
  private selectedRequirementHO: boolean | null = null
  private hasDisability = false

  private showEmailTemplateError = false
  private templatesIndex: any
  private statusForEmail = ''
  private selectedEmailTemplatesForStatus = ''

  private selectedEmailHistoryItem: any = null

  private tAccountCreated = false

  private skills: any[] = []

  private avatarImage: string | null = null
  private commentsHistory: ApplicationComment[] = []
  private comment = ''
  private commentRating = 1

  @Watch('tAccountCreated')
  changedTAccountCreated(val: any) {
    // console.log('[ApplicationsCommentForm -> Watch] tAccountCreated:', this.tAccountCreated)
    // console.log('[ApplicationsCommentForm -> Watch] val:', val)
    this.dataSet.tAccountCreated = val
  }

  @Watch('dataSet.commentsHistory')
  changedCommentsHistory(val: any) {
    console.log('[] Change of comments history detected:', val)
    this.commentsHistory = _.orderBy(val, ['commentedAt'], ['desc'])
  }

  get getActualFormData() {
    if (this.dataSet.invitedToJobInterviewAt && this.invitedToJobInterviewTime) {
      console.log('[ApplicationsCommentForm] It should be set the correct invitation date and time for dataSet')
      const correctTime = new Date(this.dataSet.invitedToJobInterviewAt)
      correctTime.setHours(parseInt(this.invitedToJobInterviewTime.substr(0, 2)))
      correctTime.setMinutes(parseInt(this.invitedToJobInterviewTime.substr(3, 2)))
      this.dataSet.invitedToJobInterviewAt = correctTime.toISOString()
    }
    if (this.selectedEmailTemplatesForStatus?.length > 0) {
      this.dataSet.emailTemplateId = this.selectedEmailTemplatesForStatus
    } else {
      this.dataSet.emailTemplateId = undefined
    }
    // if (this.tAccountCreated) {
    //   this.dataSet.tAccountCreated = true
    // } else {
    //   this.dataSet.tAccountCreated = false
    // }
    return this.dataSet
  }

  get temporaryWorkerSelected() {
    return this.temporaryWorkerSelectedValue || false
  }

  get directBrokerWorkerSelected() {
    return this.directBrokerWorkerSelectedValue || false
  }

  formattedDate(date: string) {
    console.log('[ApplicationsCommentForm] parse date:', date)
    return date ? moment(new Date(date)).format('DD.MM.YYYY [um] HH:mm:ss') : ''
  }

  decisionChanged(evt: any) {
    console.log('[ApplicationsCommentForm] Decision changed:', evt)
    this.dataSet.hired = evt === 'hired'
    this.showHiringDate = evt === 'hired'

    this.dataSet.rejected = evt === 'rejected'
    this.showRejectedReason = evt === 'rejected'

    this.dataSet.waitingListIntermediateNote = evt === 'waitingList'
  }

  videoAssessmentChanged(evt: any) {
    console.log('[ApplicationsCommentForm] Video assessment changed:', evt)
    this.dataSet.videoAssessment = evt === 2 ? null : evt
  }

  RequirementHOChanged(evt: any) {
    console.log('[ApplicationsCommentForm] HO requirement changed:', evt)
    this.dataSet.requirementHO = evt === 2 ? null : evt
  }

  formatDate(date: string) {
    console.log('[ApplicationsCommentForm] format this date for picker:', date)
    return date
  }

  changedTW(evt: any) {
    console.log('[ApplicationsCommentForm] Changed temporary worker checkbox:', evt)
    this.temporaryWorkerSelectedValue = evt
    if (!this.dataSet.applicationChannel || this.dataSet.applicationChannel.length === 0) {
      this.dataSet.applicationChannel = 'zeitarbeitsfirma'
    }
  }

  changedDW(evt: any) {
    console.log('[ApplicationsCommentForm] Changed direct worker checkbox:', evt)
    this.directBrokerWorkerSelectedValue = evt
    if (!this.dataSet.applicationChannel || this.dataSet.applicationChannel.length === 0) {
      this.dataSet.applicationChannel = 'direktvermittlung'
    }
  }

  changedDisability(evt: any) {
    console.log('[ApplicationsCommentForm] Changed disability checkbox:', evt)
    this.hasDisability = evt
  }

  created() {
    this.root = this.$root as VueGevekom
    console.log('[ApplicationsCommentForm] Created the form component:', this.originalData)

    if (this.getAvatarUrl) {
      this.avatarImage = this.getAvatarUrl()
    }
    let tmp = { ...this.originalData }
    tmp = this.checkFormData(tmp)
    this.dataSet = tmp
    this.commentsHistory = _.orderBy(this.originalData.commentsHistory, ['commentedAt'], ['desc'])

    console.log('[ApplicationsCommentForm] work with this dataSet:', this.dataSet)
    this.tAccountCreated = this.dataSet.tAccountCreated || false

    if (!this.root.emailTemplatesService.isInited) {
      const tenantId = this.$store.state.auth.tenantId
      const token = this.$store.state.auth.tokenDictionary.Recruiting.tokens.recruitmentAPIToken

      this.root.emailTemplatesService.initService(tenantId, token)
    }
    this.root.emailTemplatesService.emailTemplatesIndex$.subscribe((templatesIndex) => {
      console.log('[ApplicationsCommentForm] Received templates index', templatesIndex)
      if (!templatesIndex) {
        this.root.emailTemplatesService.getIndex$().subscribe()
        // } else if (templatesIndex.length === 0) {
        // this.showEmailTemplateError = true
      } else {
        this.templatesIndex = templatesIndex || []
      }
    })
  }

  getAvatarUrl(): string | null {
    console.log('[ApplicationsCommentForm] asked for avatar url for.')

    if (!this.originalData?.avatar?.url) {
      return null
    }
    if (process.env.NODE_ENV === 'development') {
      return `https://${process.env.VUE_APP_BASE_URL}${this.originalData?.avatar?.url}?_token_=${this.$store.state.auth.user.tokens.Recruiting.tokens.recruitmentImageToken}`
    }
    return `${this.originalData?.avatar?.url}?_token_=${this.$store.state.auth.user.tokens.Recruiting.tokens.recruitmentImageToken}`
  }

  parseDate(date: string) {
    console.log('[ApplicationsCommentForm] parse date:', date)
    return date ? moment(new Date(date)).format('DD.MM.YYYY') : ''
  }

  validate() {
    // this.form.validate()
  }

  reset() {
    // this.form.reset()
    console.log('[ApplicationsCommentForm] Reset')
  }

  resetValidation() {
    // this.form.resetValidation()
  }

  checkFormData(data: any) {
    if (!data.birthday) {
      data.birthday = ''
    } else {
      data.birthday = moment(new Date(data.birthday)).format('YYYY-MM-DD')
    }
    if (!data.applicationReceipt) {
      data.applicationReceipt = moment().format('YYYY-MM-DD')
    } else {
      data.applicationReceipt = moment(new Date(data.applicationReceipt)).format('YYYY-MM-DD')
    }
    if (!data.acknowledgementOfApplicationSentAt) {
      data.acknowledgementOfApplicationSentAt = data.applicationReceipt
    } else {
      data.acknowledgementOfApplicationSentAt = moment(new Date(data.acknowledgementOfApplicationSentAt)).format('YYYY-MM-DD')
    }
    if (!data.invitedToJobInterviewAt) {
      data.invitedToJobInterviewAt = ''
    } else {
      this.invitedToJobInterviewTime = moment(new Date(data.invitedToJobInterviewAt)).format('HH:mm')
      data.invitedToJobInterviewAt = moment(new Date(data.invitedToJobInterviewAt)).format('YYYY-MM-DD')
    }
    if (!data.feedbackJobInterviewAt) {
      data.feedbackJobInterviewAt = ''
    } else {
      data.feedbackJobInterviewAt = moment(new Date(data.feedbackJobInterviewAt)).format('YYYY-MM-DD')
    }
    if (!data.applicationAs || data.applicationAs.length === 0) {
      data.applicationAs = 'agent'
    }
    if (!data.feedbackTest || data.feedbackTest.length === 0) {
      data.feedbackTest = 'offen'
    }
    if (!data.documents) {
      data.documents = []
    }
    if (!data.skills) {
      data.skills = []
    }

    if (!data.emailSenderAddress && this.$store.state?.company?.configs?.emailSenders?.length >= 1) {
      // data.emailSenderAddress = this.$store.state.company.configs.emailSenders[0]
      data.emailSenderAddress = null
    } else if (!data.emailSenderAddress) {
      data.emailSenderAddress = null
    }

    this.temporaryWorkerSelectedValue = data.temporaryWorker || false
    this.directBrokerWorkerSelectedValue = data.directBrokerWorker || false
    this.selectedDecision = data.hired ? 'hired' : data.rejected ? 'rejected' : data.waitingListIntermediateNote ? 'waitingList' : null
    this.selectedVideoAssessment = data.videoAssessment
    this.selectedRequirementHO = data.requirementHO
    this.showHiringDate = data.hired || false
    this.showRejectedReason = data.rejected || false
    this.hasDisability = data.hasDisability || false
    this.statusValue = data.status
    this.statusForEmail = data.status
    return data
  }

  handleStatusChange(evt: any) {
    console.log('[ApplicationsCommentForm] Status has changed:', evt)
    this.$nextTick(() => {
      this.statusForEmail = evt
    })
  }

  // get getEmailTemplateForStatus(): string {
  //   const templates = this.emailTemplatesForStatus
  //   return this.statusForEmail || ''
  // }

  get emailTemplatesForStatus() {
    const templates = _.filter(this.templatesIndex, { templateType: this.statusForEmail })
    console.log('[ApplicationsCommentForm] get emailTemplatesForStatus:', templates)
    return templates
  }

  getEmailSentAt(sentAt: string) {
    return moment(new Date(sentAt)).format('DD.MM.YYYY HH:mm')
  }

  getAutocompleteOptions(options: any[]): string[] {
    const ret: string[] = []
    for (const option of options) {
      ret.push(option.displayText)
    }
    return ret
  }

  addedDocumentClicked(doc: any[]) {
    console.log('[ApplicationsCommentForm] added new Document called with:', doc)
    this.dataSet.documents = doc
    this.addedDocument(doc)
  }

  getMappedSkills() {
    const skillsList = _.reject(this.$store.state.applications.skillsList, { active: false })
    if (skillsList?.length > 0) {
      const newMappedSkills = []
      for (let i = 0; i < skillsList.length + 1; i += 2) {
        if (!skillsList[i]) {
          break
        }
        const skill1 = { ...skillsList[i], checked: !!_.find(this.originalData.skills, { value: skillsList[i].value }) }
        let skill2 = null
        if (skillsList[i + 1]) {
          skill2 = { ...skillsList[i + 1], checked: !!_.find(this.originalData.skills, { value: skillsList[i + 1].value }) }
        }
        newMappedSkills.push({ left: skill1, right: skill2 })
      }
      this.skills = newMappedSkills
    }
  }

  changedSkillCheckbox(evt: any, skill: any) {
    console.log('[ApplicationsCommentForm] Event in changed skill checkbox:', evt)
    console.log('[ApplicationsCommentForm] Skill in changed skill checkbox:', skill)
    if (evt === true) {
      if (!_.find(this.dataSet.skills, { value: skill.value })) {
        this.dataSet.skills.push({ ...skill })
      }
    } else {
      this.dataSet.skills = _.reject(this.dataSet.skills, { value: skill.value })
    }
  }

  addComment() {
    console.log('[ApplicationsCommentForm] Kommentar hinzufügen...')
    const testComment: ApplicationComment = {
      commenterName: `${this.$store.state.auth.user.firstName} ${this.$store.state.auth.user.lastName}`,
      commenterLogin: this.$store.state.auth.user.login,
      commenterId: this.$store.state.auth.user.id,
      comment: this.comment,
      rating: this.commentRating
    }
    this.root.applicationService.addCommentToApplication$(this.originalData.id, testComment).subscribe((response: any) => {
      console.log('[ApplicationsCommentForm] Kommentar hinzufügen response:', response)
      this.dataSet = null
      let tmp = { ...response.data.application }
      tmp = this.checkFormData(tmp)
      this.dataSet = tmp
      this.changedCommentsHistory(tmp.commentsHistory)
    })
  }
}
