
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { VueGevekom } from '@/components/applications/Form.interfaces'
import { forkJoin, Observable } from 'rxjs'
import GkDatePicker from '@/components/DatePicker.vue'
import _ from 'lodash'
import moment from 'moment'
import { map } from 'rxjs/operators'

interface TableItem {
  dayDate: string
  branch: string
  applicationsCount: number
  zaDvCount: number
  testDoneCount: number
  testPassedCount: number
  onlineInterviewsCount: number
  hiredThisWeekOfYearCount: number
}

@Component({ components: { GkDatePicker } })
export default class DailyFeedback extends Vue {
  private root!: VueGevekom

  private branchesList: any[] = []
  private list: any[] = []
  // private statDate = moment('2021-01-22').startOf('day')
  // private statPickerDate = moment('2021-01-22').startOf('day').format('YYYY-MM-DD')
  private statDate = moment().startOf('day')
  private statPickerDate = moment().startOf('day').format('YYYY-MM-DD')

  @Watch('statPickerDate')
  watchStatDate(newValue: any, oldValue: any) {
    console.log('[DailyFeedback] Watched statPickerDate NEW:', newValue)
    console.log('[DailyFeedback] Watched statPickerDate OLD:', oldValue)
    if (newValue !== oldValue) {
      this.statDate = moment(newValue).startOf('day')
      // this.createItemsList()
    }
  }

  private tableItems: TableItem[] = []
  private tableSumRow!: TableItem
  private tableHeaders = [
    {
      text: 'Datum',
      align: 'start',
      value: 'dayDate'
    },
    { text: 'Standort', value: 'branch' },
    { text: 'Bewerbungen', value: 'applicationsCount', align: 'center' },
    { text: 'ZA/DV', value: 'zaDvCount', align: 'center' },
    { text: 'Test erledigt', value: 'testDoneCount', align: 'center' },
    { text: 'Test bestanden', value: 'testPassedCount', align: 'center' },
    { text: 'Onlineinterviews', value: 'onlineInterviewsCount', align: 'center' },
    { text: 'Eingestellt an diesem Tag', value: 'hiredThisWeekOfYearCount', align: 'center' }
  ]

  private search = ''

  @Prop({ default: null }) readonly applicationsList!: Observable<any[]>
  @Prop({ default: null }) readonly branches!: Observable<any[]>

  get statisticsDate() {
    console.log(`[DailyFeedback] Getter statisticsDate: ${this.statDate}`)
    if (!this.statDate) {
      return moment().startOf('day')
    }
    return this.statDate.format('DD.MM.YYYY')
  }

  get countApplicationsForStatDate() {
    const l = _.filter(this.list, (a: any) => {
      if (!a.applicationReceipt) {
        a.applicationReceipt = a.createdDate
      }
      return a.applicationReceipt.substr(0, 10) === this.statDate.format('YYYY-MM-DD')
    })
    return l.length
  }

  countApplicationsForStatDateAndBranch(branch: string) {
    const l = _.filter(this.list, (a: any) => {
      if (!a.applicationReceipt) {
        a.applicationReceipt = a.createdDate
      }
      return a.branch === branch && a.applicationReceipt.substr(0, 10) === this.statDate.format('YYYY-MM-DD')
    })
    return l.length
  }

  created() {
    console.log('[DailyFeedback] IS CREATED')
    this.root = this.$root as VueGevekom
    this.branches.subscribe((branches) => {
      this.branchesList = _.orderBy(branches, ['displayText'])
      this.subscribeToApplicationsList()
    })
  }

  private subscribeToApplicationsList() {
    // this.$store.commit('showProgressBar')
    this.applicationsList.subscribe((list) => {
      console.log('[DailyFeedback] received applications list:', list)
      this.list = [...list]
      // this.createItemsList(null)
    })
  }

  private loadApplicationsForSelectedDay() {
    this.$store.commit('showProgressBar')
    const forkJoins: Observable<any>[] = []
    forkJoins.push(
      this.root.applicationService.getDailyStatsIndex$(this.statDate.format('YYYY-MM-DD')).pipe(
        map((response: any) => {
          return response.data.counts
        })
      )
    )

    forkJoin(forkJoins).subscribe(
      (response: any[]) => {
        console.log('[DailyFeedback] received applications list:', response)
        // this.list = [...response[0]]
        this.createItemsList(response[0])
        this.$store.commit('hideProgressBar')
      },
      (error: any) => {
        console.error('[DailyFeedback] error:', error)
        this.$store.commit('hideProgressBar')
      }
    )
  }

  loadDaySelection() {
    console.log('[DailyFeedback] Load day selection')
    this.tableItems = []
    this.tableSumRow = {
      dayDate: '',
      branch: 'Summen:',
      applicationsCount: 0,
      zaDvCount: 0,
      testDoneCount: 0,
      testPassedCount: 0,
      onlineInterviewsCount: 0,
      hiredThisWeekOfYearCount: 0
    }
    this.loadApplicationsForSelectedDay()
  }

  handleDateChange(event: any) {
    console.log('[DailyFeedback] handle change of statistics date:', event)
  }

  createItemsList(statsObject: any) {
    console.log('[DailyFeedback] create new data table items list from stats: ', statsObject)
    if (!this.branchesList || !statsObject) {
      return
    }

    const dayDate = this.statDate.format('YYYY-MM-DD')

    const newList: TableItem[] = []
    const newSumRow = {
      dayDate,
      branch: 'Summen:',
      applicationsCount: statsObject.total.applicationsCount,
      zaDvCount: statsObject.total.zaDvCount,
      testDoneCount: statsObject.total.testDoneCount,
      testPassedCount: statsObject.total.testPassedCount,
      onlineInterviewsCount: statsObject.total.onlineInterviewsCount,
      hiredThisWeekOfYearCount: statsObject.total.hiredCount
    }

    for (const b of this.branchesList) {
      // console.log('[DailyFeedback] create new data table items list from stats: ', b)
      // console.log('[DailyFeedback] create new data table items list from stats: ', statsObject[b.value])
      const newRow = {
        dayDate,
        branch: b.displayText,
        applicationsCount: statsObject[b.value].applicationsCount,
        zaDvCount: statsObject[b.value].zaDvCount,
        testDoneCount: statsObject[b.value].testDoneCount,
        testPassedCount: statsObject[b.value].testPassedCount,
        onlineInterviewsCount: statsObject[b.value].onlineInterviewsCount,
        hiredThisWeekOfYearCount: statsObject[b.value].hiredCount
      }

      newList.push(newRow)
    }
    this.tableSumRow = newSumRow

    this.tableItems = newList
    console.log('[DailyFeedback] new data table items list:', this.tableItems)
  }
}
