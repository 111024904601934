
import { Component, Emit, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { DataTableHeader } from 'vuetify'
import _ from 'lodash'
import { EmailTemplate } from '@/appInterfaces/emailTemplates.interfaces'
import { StatusType } from '@/appInterfaces/applications.interfaces'

@Component
export default class EmailTemplatesTable extends Vue {
  private search = ''

  private dialog = false
  private dialogDelete = false
  private editedIndex = -1
  private editedItem = {}
  private defaultItem = {}

  private headers = [
    {
      text: 'Bearbeitet am',
      align: 'start',
      value: 'updatedAt',
      class: 'updated-at'
    },
    { text: 'Typ', value: 'templateType', class: 'template-type' },
    { text: 'Name', value: 'name' },
    { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
  ]

  moment = moment

  @Prop({ default: [] }) readonly emailTemplates!: EmailTemplate[]
  @Prop() readonly statusTypes!: StatusType[]

  get getEmailTemplates() {
    const evaluatedEmailTemplates = []
    for (const template of this.emailTemplates) {
      evaluatedEmailTemplates.push({
        ...template
      })
    }
    return evaluatedEmailTemplates
  }

  get formTitle() {
    return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
  }

  private tableFooterOptions = {
    pagination: {
      itemsPerPage: 25
    }
  }

  @Emit('editEmailTemplate')
  editItem(item: any) {
    console.log('[EmailTemplatesTable] Edit this item:', item)
    // console.log('[EmailTemplatesTable] Edit this item with id:', props)
    return item
  }

  @Emit('deleteEmailTemplate')
  deleteItem(item: any) {
    console.log('[EmailTemplatesTable] Delete this item:', item)
    return item
  }

  customFilter(value: any, search: string): boolean {
    // console.log('[EmailTemplatesTable] filter value:', value)
    // console.log('[EmailTemplatesTable] search value:', search)
    // console.log('[EmailTemplatesTable] item value:', item)
    if (value != null && search != null && typeof value === 'string') {
      if (moment(value).isValid()) {
        value = moment(value).format('DD.MM.YYYY')
      }
      const status = _.find(this.$store.state.company.configs.status, { value })
      if (status) {
        value = status.displayText
      }
      const job = _.find(this.$store.state.company.configs.jobs, { value })
      if (job) {
        value = job.displayText
      }
      return value.toLowerCase().includes(search.toLowerCase())
    }
    return false
  }

  editRow(
    item: any,
    options: {
      expand: (value: boolean) => void
      headers: DataTableHeader[]
      isExpanded: boolean
      isMobile: boolean
      isSelected: boolean
      item: any
      select: (value: boolean) => void
    }
  ) {
    // console.log('[EmailTemplatesTable] Edit row with item:', item)
    console.log('[EmailTemplatesTable] Edit row with options:', options)
    this.editItem(item)
  }

  deleteItemConfirm() {
    console.log('[EmailTemplatesTable] Close confirmed delete dialog')
  }

  close() {
    console.log('[EmailTemplatesTable] Close this dialog')
  }

  closeDelete() {
    console.log('[EmailTemplatesTable] Close delete dialog')
  }

  save() {
    console.log('[EmailTemplatesTable] Save ')
  }

  getStatusType(statusValue: string) {
    // console.log('[EmailTemplatesTable] Find displayText for status:', statusValue)
    const status = _.find(this.statusTypes, { value: statusValue })
    return status ? status.displayText : ''
  }
}
